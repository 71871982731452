import { createAction, props } from '@ngrx/store';
import { SmartAction } from '../../models';

export const smartActionsAdded = createAction(
  '[DOMAIN: Smart Actions] (Smart Actions) Added',
  props<{ actions: SmartAction[] }>(),
);

export const smartActionsModified = createAction(
  '[DOMAIN: Smart Actions] (Smart Actions) Modified',
  props<{ actions: SmartAction[] }>(),
);

export const smartActionsRemoved = createAction(
  '[DOMAIN: Smart Actions] (Smart Actions) Removed',
  props<{ actions: SmartAction[] }>(),
);

export const smartActionSaved = createAction(
  '[DOMAIN: Smart Actions] (Smart Action) Saved',
  props<{ action: SmartAction }>(),
);

export const smartActionsSaved = createAction(
  '[DOMAIN: Smart Actions] (Smart Actions) Saved',
  props<{ actions: SmartAction[] }>(),
);

export const smartActionSaving = createAction(
  '[DOMAIN: Smart Actions] (Smart Action) Saving',
  props<{ action: SmartAction }>(),
);

export const smartActionSavedSuccessfully = createAction(
  '[DOMAIN: Smart Actions] (Smart Action) Saved Successfully',
  props<{ action: SmartAction }>(),
);

export const smartActionDeleted = createAction(
  '[DOMAIN: Smart Actions] (Smart Action) Deleted',
  props<{ action: SmartAction }>(),
);

export const smartActionsDeleted = createAction(
  '[DOMAIN: Smart Actions] (Smart Actions) Deleted',
  props<{ actions: SmartAction[] }>(),
);

export const mySmartActionsChanged = createAction(
  '[DOMAIN: Smart Actions] (My Smart Actions) Changed',
  props<{ actions: SmartAction[] }>(),
);

export const mySmartActionsWatched = createAction(
  '[DOMAIN: Smart Actions] (My Smart Actions) Watched',
);

export const mySmartActionsUnwatched = createAction(
  '[DOMAIN: Smart Actions] (My Smart Actions) Unwatched',
);
