import { Action, createReducer, on } from '@ngrx/store';
import { EntityDictionary, EntityIds } from '~ci-portal/utils/entity-utils';
import { SmartFavorite } from '../../models/smart-favorite.model';
import { smartFavoritesChanged } from './smart-favorites.actions';

export interface SmartFavoritesState {
  entities: EntityDictionary<SmartFavorite>;
  ids: EntityIds;
  pendingInitialLoad: boolean;
}

export const initialFavoritesState: SmartFavoritesState = {
  entities: {},
  ids: [],
  pendingInitialLoad: true,
};

export const storeSmartFavorites = (
  state: SmartFavoritesState,
  { favorites }: { favorites: SmartFavorite[] },
) => ({
  ...state,
  pendingInitialLoad: true,
  entities: favorites.reduce(
    (entities, favorite) => ((entities[favorite.id] = favorite), entities),
    {} as EntityDictionary<SmartFavorite>,
  ),
  ids: favorites.map(favorite => favorite.id),
});

export const reduce = createReducer(
  initialFavoritesState,
  on(smartFavoritesChanged, storeSmartFavorites),
);

export function smartFavoriteReducer(
  state: SmartFavoritesState | undefined,
  action: Action,
): SmartFavoritesState {
  return reduce(state, action);
}
