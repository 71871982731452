import { createAction, props } from '@ngrx/store';

export const blockingSpinnerShown = createAction(
  '[LAYOUT: Blocking Spinner] (Spinner) Shown',
  props<{ title?: string }>(),
);

export const blockingSpinnerHidden = createAction(
  '[LAYOUT: Blocking Spinner] (Spinner) Hidden',
);
