import { createSelector } from '@ngrx/store';
import { BlockingSpinnerState } from './blocking-spinner.state';
import { featureState, LayoutState } from './feature';

export const blockingSpinnerState = createSelector(
  featureState,
  (state: LayoutState) => state.blockingSpinner,
);

export const spinnerShown = createSelector(
  blockingSpinnerState,
  (state: BlockingSpinnerState) => state.shown,
);

export const spinnerTitle = createSelector(
  blockingSpinnerState,
  (state: BlockingSpinnerState) => state.title ?? 'Please wait...',
);
