import type { DocumentChangeAction } from '@angular/fire/compat/firestore';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import type { EntityDictionary } from './state-types';

export const normalizeEntityKey = <T>(keyProp: keyof T, entity: T) =>
  typeof entity[keyProp] === 'number'
    ? Number(entity[keyProp])
    : String(entity[keyProp]);

export const mapEntityToDictionary =
  <T>(keyProp: keyof T) =>
  (acc: EntityDictionary<T>, entity: T): EntityDictionary<T> => ({
    ...acc,
    [normalizeEntityKey(keyProp, entity)]: entity,
  });

export const mapDocumentChangeActionToEntity = <T>(
  trackFirebaseId: boolean | string = true,
) =>
  pipe(
    map((changes: DocumentChangeAction<T>[]): T[] =>
      changes.map(({ payload }) =>
        trackFirebaseId === true
          ? {
            ...payload.doc.data(),
            id: payload.doc.id,
          }
          : trackFirebaseId
            ? {
              ...payload.doc.data(),
              [trackFirebaseId]: payload.doc.id,
            }
            : { ...payload.doc.data() },
      ),
    ),
  );
