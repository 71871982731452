import { ModuleWithProviders, NgModule } from '@angular/core';
import { CiEnvironment } from './ci-environment.model';
import { ENVIRONMENT } from './environment.token';

@NgModule({})
export class EnvironmentModule {
  static withEnvironment(
    environment: CiEnvironment
  ): ModuleWithProviders<EnvironmentModule> {
    return {
      ngModule: EnvironmentModule,
      providers: [
        {
          provide: ENVIRONMENT,
          useValue: environment,
        },
      ],
    };
  }
}
