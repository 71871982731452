import { Subject } from 'rxjs';

export class Signal extends Subject<{ timestamp: Date }> {
  broadcast() {
    this.next({ timestamp: new Date() });
  }

  finalBroadcast() {
    this.broadcast();
    this.complete();
  }
}
