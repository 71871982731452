import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  spinnerShown,
  spinnerTitle,
} from '../../store/blocking-spinner.selectors';

@Component({
  selector: 'cip-blocking-spinner',
  templateUrl: './blocking-spinner.component.html',
  styleUrls: ['./blocking-spinner.component.scss'],
})
export class BlockingSpinnerComponent {
  shown$: Observable<boolean> = this.store.select(spinnerShown);
  title$: Observable<string> = this.store.select(spinnerTitle);

  constructor(private readonly store: Store) {}
}
