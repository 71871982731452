import { Action, createReducer, on } from '@ngrx/store';
import {
  EntityState,
  mergeEntities,
  removeEntities,
} from '~ci-portal/utils/entity-utils';
import { Priority } from '../../models/priority.model';
import {
  prioritiesAdded,
  prioritiesModified,
  prioritiesRemoved,
} from './priority.actions';

export const initialPriorityState: EntityState<Priority> = {
  entities: {},
  ids: [],
  pendingInitialLoad: true,
};

export const mergePriorities = (
  state: EntityState<Priority>,
  { priorities }: { priorities: Priority[] },
) => mergeEntities(state, priorities, 'key');

export const removePriorities = (
  state: EntityState<Priority>,
  { priorities }: { priorities: Priority[] },
) => removeEntities(state, priorities, 'key');

export const reduce = createReducer(
  initialPriorityState,
  on(prioritiesAdded, prioritiesModified, mergePriorities),
  on(prioritiesRemoved, removePriorities),
);

export function priorityReducer(
  state = initialPriorityState,
  action: Action,
): EntityState<Priority> {
  return reduce(state, action);
}
