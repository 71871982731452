import { Action, createReducer, on } from '@ngrx/store';
import {
  goalsAdded,
  goalsModified,
  goalsRemoved,
} from './goal.actions';
import { EntityState, mergeEntities, removeEntities } from '~ci-portal/utils/entity-utils';
import { Goal } from '../../models';

export const initialGoalState: EntityState<Goal> = {
  entities: {},
  ids: [],
  pendingInitialLoad: true,
};

export const mergeGoals = (
  state: EntityState<Goal>,
  { goals }: { goals: Goal[] },
) => mergeEntities(state, goals, 'key');

export const removeGoals = (
  state: EntityState<Goal>,
  { goals }: { goals: Goal[] },
) => removeEntities(state, goals, 'key');

export const reduce = createReducer(
  initialGoalState,
  on(goalsAdded, goalsModified, mergeGoals),
  on(goalsRemoved, removeGoals),
);

export function goalReducer(
  state = initialGoalState,
  action: Action,
): EntityState<Goal> {
  return reduce(state, action);
}
