import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigationAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { filter, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { AuthenticatedUser, authenticatedUser } from '~ci-portal/core/auth';
import {
  routeIncludesPath,
  routeMatchesPath,
  routeNotIncludesPath,
  routeNotMatchesPath,
} from '~ci-portal/utils/common-utils';
import { setUpEntityChangeWatcherByRoutes } from '~ci-portal/utils/entity-utils';
import { myProjectTeamMemberIds } from '../project-team-member/project-team-member.selectors';
import {
  milestoneDeleted,
  milestonesAdded,
  milestoneSaved,
  milestonesModified,
  milestonesRemoved,
  milestonesSaved,
  myProjectMilestonesChanged,
  myProjectMilestonesUnwatched,
  myProjectMilestonesWatched,
  projectMilestonesChanged,
  projectMilestonesUnwatched,
  projectMilestonesWatched,
} from './milestones.actions';
import { MilestonesService } from './milestones.service';

@Injectable()
export class MilestoneEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly milestones: MilestonesService,
    private readonly store: Store,
  ) {}

  watchProjectMilestones$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigationAction),
      routeIncludesPath('/ci-projects/edit', '/ci-projects/report'),
      map(({ root }) => root.firstChild?.params.projectId),
      filter((projectId?: string | null): projectId is string => !!projectId),
      map(projectId => projectMilestonesWatched({ projectId })),
    ),
  );

  unwatchProjectMilestones$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigationAction),
      routeNotIncludesPath('/ci-projects/edit', '/ci-projects/report'),
      map(() => projectMilestonesUnwatched()),
    ),
  );

  watchMyProjectMilestones$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigationAction),
      routeMatchesPath('/'),
      switchMap(() => this.store.select(authenticatedUser)),
      filter((user?: AuthenticatedUser): user is AuthenticatedUser => !!user),
      map(() => myProjectMilestonesWatched()),
    ),
  );

  unwatchMyProjectMilestones$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigationAction),
      routeNotMatchesPath('/'),
      map(() => myProjectMilestonesUnwatched()),
    ),
  );

  watchEntityChanges$ = createEffect(() =>
    this.actions$.pipe(
      setUpEntityChangeWatcherByRoutes(
        this.actions$,
        this.milestones,
        milestones => milestonesAdded({ milestones }),
        milestones => milestonesModified({ milestones }),
        milestones => milestonesRemoved({ milestones }),
        '/ci-projects/list',
      ),
    ),
  );

  trackProjectMilestones$ = createEffect(() =>
    this.actions$.pipe(
      ofType(projectMilestonesWatched),
      switchMap(({ projectId }) =>
        this.milestones.byProjectID(projectId).pipe(
          map(milestones => projectMilestonesChanged({ milestones })),
          takeUntil(this.actions$.pipe(ofType(projectMilestonesUnwatched))),
        ),
      ),
    ),
  );

  trackMyProjectMilestones$ = createEffect(() =>
    this.actions$.pipe(
      ofType(myProjectMilestonesWatched),
      switchMap(() =>
        this.store.select(myProjectTeamMemberIds).pipe(
          filter(
            (memberIds?: string[]): memberIds is string[] =>
              !!memberIds?.length,
          ),
          switchMap(memberIds =>
            this.milestones.byTeamMemberIds(memberIds).pipe(
              map(milestones => myProjectMilestonesChanged({ milestones })),
              takeUntil(this.actions$.pipe(ofType(projectMilestonesUnwatched))),
            ),
          ),
          takeUntil(this.actions$.pipe(ofType(projectMilestonesUnwatched))),
        ),
      ),
    ),
  );

  updateMilestone$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(milestoneSaved),
        map(({ milestone }) => ({
          ...milestone,
          id: milestone.id ?? uuidv4(),
        })),
        mergeMap(milestone => this.milestones.save(milestone)),
      ),
    { dispatch: false },
  );

  deleteMilestone$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(milestoneDeleted),
        mergeMap(({ milestone }) => this.milestones.delete(milestone)),
      ),
    { dispatch: false },
  );

  saveMilestones$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(milestonesSaved),
        mergeMap(({ milestones }) => this.milestones.bulkUpdate(milestones)),
      ),
    { dispatch: false },
  );
}
