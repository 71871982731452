import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { authenticatedUser, userLoggingOut } from '~ci-portal/core/auth';
import { GLOBAL_LINKS } from '../../global-links';
import { ModuleArea, SITE_MODULES, SiteModule } from '../../site-modules';
import { areaSelected, moduleSelected } from '../../store/modules.actions';
import { selectUrl } from '../../store/router-store.state';

@Component({
  selector: 'cip-site-header',
  templateUrl: './site-header.component.html',
  styleUrls: ['./site-header.component.scss'],
})
export class SiteHeaderComponent {
  readonly authenticatedUser$ = this.store.select(authenticatedUser);
  readonly currentUrl$ = this.store.select(selectUrl);
  readonly links = GLOBAL_LINKS;
  readonly modules = SITE_MODULES;

  constructor(private readonly store: Store, private readonly router: Router) {
  }

  logOut() {
    this.store.dispatch(userLoggingOut());
  }

  areaSelected({ module, area }: { module: SiteModule; area: ModuleArea }) {
    this.store.dispatch(areaSelected({ module, area }));
  }

  navigateToLink(uri: string) {
    this.router.navigateByUrl(uri);
  }

  moduleSelected(module: SiteModule) {
    this.store.dispatch(moduleSelected({ module }));
  }
}
