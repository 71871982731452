import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { format } from 'date-fns';
import { map, switchMap } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import {
  loggedInUserReloaded,
  loggedInUserVerified,
  userAuthenticated,
  userLoggingOut,
} from './auth.actions';

@Injectable()
export class UserEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly users: UserService,
  ) {}

  restoreLoggedInUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loggedInUserVerified),
      map(({ uid }) => uid),
      map(uid => ({
        ref: this.users.getRef(uid),
      })),
      switchMap(({ ref }) =>
        ref.get().pipe(
          map(snap => ({
            exists: snap.exists,
            ref,
            user: snap.data(),
          })),
        ),
      ),
      map(({ exists, user }) =>
        exists && !!user ? loggedInUserReloaded({ user }) : userLoggingOut(),
      ),
    ),
  );

  replicateUserInFirebase$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userAuthenticated, loggedInUserReloaded),
        map(({ user }) => user),
        map(user => ({
          ref: this.users.getRef(user.uid),
          user,
        })),
        switchMap(({ ref, user }) =>
          ref.get().pipe(
            map(snap => ({
              exists: snap.exists,
              ref,
              user,
            })),
          ),
        ),
        switchMap(({ exists, ref, user }) =>
          exists
            ? this.users.update(
                {
                  displayName: user.displayName,
                  email: user.email,
                  lastLoginDate: format(new Date(), 'MM/dd/yyyy HH:mm'),
                },
                ref,
              )
            : this.users.create(user, ref),
        ),
      ),
    { dispatch: false },
  );
}
