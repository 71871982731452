import { createSelector } from '@ngrx/store';
import { AuthenticatedUser } from '../models/authenticated-user.model';
import { authFeature, AuthState } from './auth.state';

export const getAuthenticatedUser = (state: AuthState) =>
  state.authenticatedUser;

export const getIsAuthenticated = (state: AuthState) => state.isAuthenticated;

export const getIsAuthenticating = (state: AuthState) => state.isAuthenticating;

export const authenticatedUser = createSelector(
  authFeature,
  getAuthenticatedUser,
);

export const isAuthenticated = createSelector(authFeature, getIsAuthenticated);

export const isAuthenticating = createSelector(
  authFeature,
  getIsAuthenticating,
);

export const authenticatedUserIsAdmin = createSelector(
  authenticatedUser,
  (user?: AuthenticatedUser) => user?.roles?.includes('admin') ?? false,
);
