import { Pipe, PipeTransform } from '@angular/core';
import { AuthenticatedUser } from '~ci-portal/core/auth';
import { SiteModule } from '../site-modules';

@Pipe({ name: 'onlyAuthorizedModules' })
export class OnlyAuthorizedModulesPipe implements PipeTransform {
  transform(modules: SiteModule[], user?: AuthenticatedUser | null): SiteModule[] {
    return modules.filter(
      module =>
        !module.roles ||
        (user
          ? module.roles.some(mrole =>
              user.roles?.some(urole => mrole === urole),
            )
          : false),
    );
  }
}
