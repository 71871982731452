import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { combineLatest, from, Observable } from 'rxjs';
import { scan } from 'rxjs/operators';
import { partition } from '~ci-portal/utils/common-utils';
import { EntityService } from '~ci-portal/utils/entity-utils';
import { Milestone } from '../../models/milestone.model';

const COLLECTION_NAME = 'ciProjectMilestones';

@Injectable({ providedIn: 'any' })
export class MilestonesService extends EntityService<Milestone> {
  constructor(firestore: AngularFirestore) {
    super(COLLECTION_NAME, 'id', firestore);
  }

  byProjectID(projectId: string): Observable<Milestone[]> {
    return this.firestore
      .collection<Milestone>(COLLECTION_NAME, ref =>
        ref.where('projectId', '==', projectId),
      )
      .valueChanges({ idField: 'id' });
  }

  byTeamMemberIds(memberIds: string[]): Observable<Milestone[]> {
    return combineLatest(
      memberIds
        .reduce(partition(), [])
        .map(memberIdChunk =>
          this.firestore
            .collection<Milestone>(COLLECTION_NAME, ref =>
              ref.where('memberId', 'in', memberIdChunk),
            )
            .valueChanges({ idField: 'id' }),
        ),
    ).pipe(scan((all: Milestone[], chunks) => chunks.flat(), []));
  }

  bulkUpdate(milestones: Milestone[]): Observable<void> {
    const batch = this.firestore.firestore.batch();
    milestones.forEach(milestone =>
      batch.set(
        this.col().doc((milestone[this.keyProp] as string).toString()).ref,
        milestone,
      ),
    );
    return from(batch.commit());
  }
}
