import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NamedLink } from '../../global-links';

@Component({
  selector: 'cip-global-links',
  templateUrl: './global-links.component.html',
  styleUrls: ['./global-links.component.scss'],
})
export class GlobalLinksComponent {
  @Input() links?: NamedLink[];
  @Output() linkSelected = new EventEmitter<string>();
}
