import { createSelector } from '@ngrx/store';
import {
  EntityState,
  getEntities,
  getIds,
  renderEntitiesAsArray,
} from '~ci-portal/utils/entity-utils';
import { Priority } from '../../models';
import { featureState, FeatureState } from '../feature.state';

export const getPriorityState = (state: FeatureState): EntityState<Priority> =>
  state.priorities;

export const priorityState = createSelector(featureState, getPriorityState);

export const priorityEntities = createSelector(priorityState, getEntities);

export const priorityIds = createSelector(priorityState, getIds);

export const allPriorities = createSelector(
  priorityEntities,
  priorityIds,
  renderEntitiesAsArray,
);

export const sortedPriorities = createSelector(
  allPriorities,
  (priorities: Priority[]) => priorities.sort((a, b) => a.order - b.order),
);
