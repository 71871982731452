import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AuthModule } from '~ci-portal/core/auth';
import { AssignedMemberPipe } from './pipes/assigned-member.pipe';
import { FormatHTMLPipe } from './pipes/format-html.pipe';
import { MemberNamePipe } from './pipes/member-name.pipe';
import { SanitizePipe } from './pipes/sanitize.pipe';

@NgModule({
  declarations: [
    SanitizePipe,
    FormatHTMLPipe,
    AssignedMemberPipe,
    MemberNamePipe,
  ],
  imports: [
    CommonModule,
    AuthModule,
    MatToolbarModule,
  ],
  exports: [
    SanitizePipe,
    FormatHTMLPipe,
    AssignedMemberPipe,
    MemberNamePipe,
  ],
})
export class SharedModule {
}
