<div class="d-flex">
  <div class="flex-grow-1">
    <div class="d-flex justify-content-between pt-3 px-3">
      <img class="logo" src="assets/hcc_logo_horizontal_web.png" alt="" />
      <cip-global-links
        [links]="links"
        (linkSelected)="navigateToLink($event)"
      ></cip-global-links>
      <cip-authenticated-user-badge
        class="align-self-start"
        [user]="authenticatedUser$ | push"
        (logOut)="logOut()"
        (administer)="navigateToLink('/admin')"
      ></cip-authenticated-user-badge>
    </div>
    <cip-site-nav
      [modules]="modules"
      [currentUrl]="currentUrl$ | async"
      [authenticatedUser]="authenticatedUser$ | async"
      (moduleSelected)="moduleSelected($event)"
      (areaSelected)="areaSelected($event)"
    ></cip-site-nav>
  </div>
</div>
