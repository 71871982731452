import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, mergeEntities, removeEntities } from '~ci-portal/utils/entity-utils';
import { OrgUnit } from '../../models';
import {
  orgUnitsAdded,
  orgUnitsModified,
  orgUnitsRemoved,
} from './org-unit.actions';

export const initialOrgUnitState: EntityState<OrgUnit> = {
  entities: {},
  ids: [],
  pendingInitialLoad: true,
};

export const mergeOrgUnits = (
  state: EntityState<OrgUnit>,
  { units }: { units: OrgUnit[] },
) => mergeEntities(state, units, 'id');

export const removeOrgUnits = (
  state: EntityState<OrgUnit>,
  { units }: { units: OrgUnit[] },
) => removeEntities(state, units, 'id');

export const reduce = createReducer(
  initialOrgUnitState,
  on(orgUnitsAdded, orgUnitsModified, mergeOrgUnits),
  on(orgUnitsRemoved, removeOrgUnits),
);

export function orgUnitReducer(
  state = initialOrgUnitState,
  action: Action,
): EntityState<OrgUnit> {
  return reduce(state, action);
}
