import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { userLoggingOut } from '~ci-portal/core/auth';

@Component({
  selector: 'cip-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent {
  public boxList: string[] = [
    'To - Do',
    'Create Project',
    'Milestones',
    'Projects',
    'CI Project Listing',
    '<strong>SMART</strong> Actions Report'
  ];
  constructor(private readonly store: Store) {}

  signOut() {
    this.store.dispatch(userLoggingOut());
  }
}
