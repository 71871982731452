import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap } from 'rxjs/operators';
import { setUpEntityChangeWatcherByRoutes } from '~ci-portal/utils/entity-utils';
import { usersAdded, usersModified, usersRemoved } from './user.actions';
import { userDeleted, userSaved } from './user.public.actions';
import { UserService } from './user.service';

@Injectable()
export class UserEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly users: UserService,
  ) {}

  watchEntityChanges$ = createEffect(() =>
    this.actions$.pipe(
      setUpEntityChangeWatcherByRoutes(
        this.actions$,
        this.users,
        users => usersAdded({ users }),
        users => usersModified({ users }),
        users => usersRemoved({ users }),
        '/ci-projects/edit',
        '/smart/team',
        '/smart/area',
        '/admin/manage-users',
        '/admin'
      ),
    ),
  );

  userSaved$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userSaved),
        mergeMap(({ user }) => this.users.save(user)),
      ),
    { dispatch: false },
  );

  userDeleted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userDeleted),
        mergeMap(({ user }) => this.users.delete(user)),
      ),
    { dispatch: false },
  );
}
