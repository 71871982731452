import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { EntityService } from '~ci-portal/utils/entity-utils';
import { User } from '../models/user.model';

@Injectable({ providedIn: 'root' })
export class UserService extends EntityService<User> {
  constructor(firestore: AngularFirestore) {
    super('users', 'uid', firestore);
  }
}
