<div *ngIf="isAuthenticated$ | async; else authenticating">
  <button (click)="logout()" mat-fab color="accent" aria-label="Log Out">
    <mat-icon>person</mat-icon>
  </button>
</div>

<ng-template #authenticating>
  <div *ngIf="isAuthenticating$ | async; else logInButton">
    Logging in...
  </div>
</ng-template>

<ng-template #logInButton>
  <button class="login-button" (click)="login()" mat-raised-button>
    Log in
  </button>
</ng-template>
