import { Action, createReducer, on } from '@ngrx/store';
import { EntityDictionary, EntityState } from '~ci-portal/utils/entity-utils';
import { ProjectFavorite } from '../../models';
import { favoritesChanged } from './favorites.actions';

export const initialFavoritesState: EntityState<ProjectFavorite> = {
  entities: {},
  ids: [],
  pendingInitialLoad: true,
};

export const storeProjectFavorites = (
  state: EntityState<ProjectFavorite>,
  { favorites }: { favorites: ProjectFavorite[] },
) => ({
  ...state,
  pendingInitialLoad: false,
  entities: favorites.reduce(
    (entities, favorite) => (
      (entities[favorite.id] = favorite), entities
    ),
    {} as EntityDictionary<ProjectFavorite>,
  ),
  ids: favorites.map(favorite => favorite.id),
});

export const reduce = createReducer(
  initialFavoritesState,
  on(favoritesChanged, storeProjectFavorites),
);

export function projectFavoritesReducer(
  state: EntityState<ProjectFavorite> | undefined,
  action: Action,
): EntityState<ProjectFavorite> {
  return reduce(state, action);
}
