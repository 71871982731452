import { Pipe, PipeTransform } from '@angular/core';
import { Milestone, ProjectTeamMember } from '~ci-portal/domains/ci-project';

@Pipe({
  name: 'assignedMember'
})
export class AssignedMemberPipe implements PipeTransform {
  transform(members?: ProjectTeamMember[] | null, milestone?: Milestone | Partial<Milestone> | null): ProjectTeamMember | undefined {
    return members && milestone
      ? members.find(member => member.id === milestone.memberId)
      : undefined;
  }
}
