import { EntityDictionary, EntityState } from './state-types';
import { mapEntityToDictionary, normalizeEntityKey } from './transformers';


export const storeEntities = <TEntity, TState extends EntityState<TEntity>>(
  state: TState,
  entities: TEntity[],
  keyProp: keyof TEntity,
): TState => ({
  ...state,
  pendingInitialLoad: false,
  entities: entities.reduce(
    mapEntityToDictionary(keyProp),
    {} as EntityDictionary<TEntity>,
  ),
  ids: entities.map(entity => entity[keyProp]),
});

export const mergeEntities = <TEntity, TState extends EntityState<TEntity>>(
  state: TState,
  entities: TEntity[],
  keyProp: keyof TEntity,
): TState => ({
  ...state,
  pendingInitialLoad: false,
  entities: entities.reduce(mapEntityToDictionary(keyProp), {
    ...state.entities,
  }),
  ids: [
    ...state.ids.filter(
      id =>
        !entities.some(entity => normalizeEntityKey(keyProp, entity) === id),
    ),
    ...entities.map(entity => entity[keyProp]),
  ],
});

export const removeEntities = <TEntity, TState extends EntityState<TEntity>>(
  state: TState,
  entities: TEntity[],
  keyProp: keyof TEntity,
): TState => ({
  ...state,
  pendingInitialLoad: false,
  entities: entities.reduce(
    (remaining: EntityDictionary<TEntity>, entity: TEntity) => (
      delete remaining[normalizeEntityKey(keyProp, entity)], remaining
    ),
    { ...state.entities },
  ),
  ids: [
    ...state.ids.filter(
      id =>
        !entities.some(entity => normalizeEntityKey(keyProp, entity) === id),
    ),
  ],
});
