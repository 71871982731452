import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap } from 'rxjs/operators';
import { loggedInUserVerified, userAuthenticated } from '~ci-portal/core/auth';
import { setUpEntityChangeWatcherByActions } from '~ci-portal/utils/entity-utils';
import {
  orgUnitDivDeleted,
  orgUnitDivsAdded,
  orgUnitDivSaved,
  orgUnitDivsModified,
  orgUnitDivsRemoved,
} from './org-unit-div.actions';
import { OrgUnitDivService } from './org-unit-div.service';

@Injectable({ providedIn: 'root' })
export class OrgUnitDivDataEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly orgUnitDivs: OrgUnitDivService,
  ) {}

  watchEntityChanges$ = createEffect(() =>
    this.actions$.pipe(
      setUpEntityChangeWatcherByActions(
        this.actions$,
        this.orgUnitDivs,
        divisions => orgUnitDivsAdded({ divisions }),
        divisions => orgUnitDivsModified({ divisions }),
        divisions => orgUnitDivsRemoved({ divisions }),
        userAuthenticated,
        loggedInUserVerified,
      ),
    ),
  );

  saveOrgUnitDiv$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(orgUnitDivSaved),
        mergeMap(({ division }) => this.orgUnitDivs.save(division)),
      ),
    { dispatch: false },
  );

  deleteOrgUnitDiv$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(orgUnitDivDeleted),
        mergeMap(({ division }) => this.orgUnitDivs.delete(division)),
      ),
    { dispatch: false },
  );
}
