<div class="module-nav">
  <ul *ngIf="modules" class="p-0 mb-0">
    <li class="mx-2 font-larger" role="link"
        [class.disabled]="module.isDisabled"
        [class.active]="module | isActiveModule: currentUrl"
        *ngFor="let module of modules | onlyVisibleModules | onlyAuthorizedModules:authenticatedUser"
        (click)="!module.isDisabled && moduleSelected.emit(module)">{{module.name}}</li>
  </ul>
</div>
<div class="area-nav text-white">
  <div *ngIf="modules | currentModule: currentUrl as module" [class.sub-nav]="module.areas">
    <ul *ngIf="module.areas" class="p-0 m-0">
      <li class="px-2 font-weight-bold" role="link"
          [class.font-weight-bold]="area | isActiveArea: currentUrl"
          [class.right-border]="!last"
          *ngFor="let area of module.areas; let last = last"
          (click)="areaSelected.emit({module: module, area: area})"
      >{{area.name}}</li>
    </ul>
  </div>
</div>
