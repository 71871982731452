import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';
import {
  loggedInUserNotFound,
  loggedInUserVerificationFailed,
  userAuthenticated,
  userLoggedOut,
} from '~ci-portal/core/auth';

@Injectable()
export class AuthEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
  ) {
  }

  redirectUserToLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          userLoggedOut,
          loggedInUserNotFound,
          loggedInUserVerificationFailed,
        ),
        switchMap(() => this.router.navigate(['login'])),
      ),
    { dispatch: false },
  );

  redirectUserToHome$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userAuthenticated),
        switchMap(() => this.router.navigateByUrl('/')),
      ),
    { dispatch: false },
  );
}
