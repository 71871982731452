import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { from, Observable } from 'rxjs';
import { EntityService } from '~ci-portal/utils/entity-utils';
import { ProjectTeamMember } from '../../models';

const COLLECTION_NAME = 'ciProjectTeams';

@Injectable({ providedIn: 'root' })
export class ProjectTeamMemberService extends EntityService<ProjectTeamMember> {
  constructor(firestore: AngularFirestore) {
    super(COLLECTION_NAME, 'id', firestore);
  }

  byEmail(email: string): Observable<ProjectTeamMember[]> {
    return from(
      this.firestore
        .collection<ProjectTeamMember>(COLLECTION_NAME, ref =>
          ref.where('email', '==', email),
        )
        .valueChanges({ idField: 'id' }),
    );
  }
}
