import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { mergeMap } from 'rxjs/operators';
import { setUpEntityChangeWatcherByRoutes } from '~ci-portal/utils/entity-utils';
import { HLC } from '../../models';
import {
  hlcDeleted,
  hlcsAdded,
  hlcSaved,
  hlcsModified,
  hlcsRemoved,
} from './hlc.actions';
import { HLCService } from './hlc.service';

@Injectable({ providedIn: 'root' })
export class HLCDataEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly hlcs: HLCService,
  ) {}

  watchEntityChanges$ = createEffect(() =>
    this.actions$.pipe(
      setUpEntityChangeWatcherByRoutes(
        this.actions$,
        this.hlcs,
        hlcs => hlcsAdded({ hlcs }),
        hlcs => hlcsModified({ hlcs }),
        hlcs => hlcsRemoved({ hlcs }),
        '/ci-projects/list',
        '/ci-projects/edit',
        '/ci-projects/report',
        '/admin/manage-hlc',
        '/admin'
      ),
    ),
  );

  updateHLC$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(hlcSaved),
        mergeMap(({ hlc }) => this.hlcs.save(hlc as HLC)),
      ),
    { dispatch: false },
  );

  removeHLCs$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(hlcDeleted),
        mergeMap(({ hlc }) => this.hlcs.delete(hlc)),
      ),
    { dispatch: false },
  );
}
