import { HLC } from '../../models';
import { Action, createReducer, on } from '@ngrx/store';
import {
  EntityState,
  mergeEntities,
  removeEntities,
} from '~ci-portal/utils/entity-utils';
import {
  hlcsAdded,
  hlcsModified,
  hlcsRemoved,
} from './hlc.actions';

export const initialHLCState: EntityState<HLC> = {
  entities: {},
  ids: [],
  pendingInitialLoad: true,
};

export const mergeHLCs = (
  state: EntityState<HLC>,
  { hlcs }: { hlcs: HLC[] },
) => mergeEntities(state, hlcs, 'id');

export const removeHLCs = (
  state: EntityState<HLC>,
  { hlcs }: { hlcs: HLC[] },
) => removeEntities(state, hlcs, 'id');

export const reduce = createReducer(
  initialHLCState,
  on(hlcsAdded, hlcsModified, mergeHLCs),
  on(hlcsRemoved, removeHLCs),
);

export function hlcReducer(
  state = initialHLCState,
  action: Action,
): EntityState<HLC> {
  return reduce(state, action);
}
