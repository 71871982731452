import { createAction, props } from '@ngrx/store';
import { AuthenticatedUser } from '../models/authenticated-user.model';

export const userLoggingIn = createAction('[AUTH] (User) Logging In');

export const userAuthenticated = createAction(
  '[AUTH] (User) Authenticated',
  props<{ user: AuthenticatedUser }>(),
);

export const authenticationFailed = createAction(
  '[AUTH] (Authentication) Failed',
  props<{ error: Error | unknown }>(),
);

export const userLoggingOut = createAction('[AUTH] (User) Logging Out');

export const userLoggedOut = createAction(
  '[AUTH] (User) Logged Out Successfully',
);

export const userLogInCanceled = createAction(
  '[AUTH] (Authentication) canceled',
);

export const loggedInUserVerified = createAction(
  '[AUTH] (Logged In User) Verified',
  props<{ uid: string }>(),
);

export const loggedInUserNotFound = createAction(
  '[AUTH] (Logged In User) Not Found',
);

export const loggedInUserVerificationFailed = createAction(
  '[AUTH] (Logged In User Verification) Failed',
);

export const loggedInUserReloaded = createAction(
  '[AUTH] (Logged In User) Reloaded',
  props<{ user: AuthenticatedUser }>(),
);
