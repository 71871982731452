import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { format } from 'date-fns';
import {from, of, pipe} from 'rxjs';
import {
  concatMap,
  filter,
  first,
  map,
  mergeMap,
  reduce,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { AuthenticatedUser, authenticatedUser } from '~ci-portal/core/auth';
import {
  purgeUnwantedProps,
  routeMatchesPath,
  routeNotMatchesPath,
} from '~ci-portal/utils/common-utils';
import { setUpEntityChangeWatcherByRoutes } from '~ci-portal/utils/entity-utils';
import { CIProject, CIProjectStage } from '../../models';
import {
  ciProjectSaving,
  myProjectsChanged,
  myProjectsUnwatched,
  myProjectsWatched,
} from '../ci-project/ci-project.actions';
import {
  ciProjectDeleted,
  ciProjectSaved,
  ciProjectSavedSuccessfully,
  ciProjectsDeleted,
  ciProjectsSaved,
  ciProjectStageSaved,
} from '../ci-project/ci-project.public.actions';
import { myProjectTeamProjectIds } from '../project-team-member/project-team-member.selectors';
import {
  ciProjectsAdded,
  ciProjectSavingInitiated,
  ciProjectsModified,
  ciProjectsRemoved,
} from './ci-project.actions';
import { CIProjectService } from './ci-project.service';

export const authenticatedUserNavigatesTo = (
  store: Store,
  ...paths: string[]
) =>
  pipe(
    ofType(routerNavigatedAction),
    routeMatchesPath(...paths),
    switchMap(() => store.select(authenticatedUser)),
    filter((user?: AuthenticatedUser): user is AuthenticatedUser => !!user),
  );

export const navigatesFrom = (...paths: string[]) =>
  pipe(ofType(routerNavigatedAction), routeNotMatchesPath(...paths));

@Injectable({ providedIn: 'root' })
export class CIProjectDataEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly ciProjects: CIProjectService,
  ) {
  }

  unwatchMyProjects$ = createEffect(() =>
    this.actions$.pipe(
      navigatesFrom('/'),
      map(() => myProjectsUnwatched()),
    ),
  );

  watchMyProjects$ = createEffect(() =>
    this.actions$.pipe(
      authenticatedUserNavigatesTo(this.store, '/'),
      map(() => myProjectsWatched()),
    ),
  );

  watchEntityChanges$ = createEffect(() =>
    this.actions$.pipe(
      setUpEntityChangeWatcherByRoutes(
        this.actions$,
        this.ciProjects,
        projects => ciProjectsAdded({ projects }),
        projects => ciProjectsModified({ projects }),
        projects => ciProjectsRemoved({ projects }),
        '/ci-projects/list',
        '/ci-projects/create',
        '/ci-projects/edit',
        '/ci-projects/report',
        '/smart/team',
        '/smart/area',
        '/admin/manage-ci-projects',
        '/admin',
      ),
    ),
  );

  trackMyProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(myProjectsWatched),
      switchMap(() =>
        this.store.select(myProjectTeamProjectIds).pipe(
          filter(
            (projectIds?: string[]): projectIds is string[] =>
              !!projectIds?.length,
          ),
          switchMap(projectIds =>
            this.ciProjects.byProjectIds(projectIds).pipe(
              map(projects => myProjectsChanged({ projects })),
              takeUntil(this.actions$.pipe(ofType(myProjectsUnwatched))),
            ),
          ),
          takeUntil(this.actions$.pipe(ofType(myProjectsUnwatched))),
        ),
      ),
    ),
  );

  createOrUpdateCIProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ciProjectSaved),
      withLatestFrom(this.store.select(authenticatedUser)),
      filter(([, user]) => !!user),
      // eslint-disable-next-line ngrx/avoid-mapping-selectors
      concatMap(([{ project, skipUser }, user]) =>
        (project.prettyId ? of(project.prettyId) : this.ciProjects.getPrettyID()).pipe(
          map(
            prettyId =>
              ({
                ...project,
                projectId: project.projectId ?? uuidv4(),
                archived: project.archived ?? false,
                created:
                  project.created ?? format(new Date(), 'M/d/yyyy h:mm aa'),
                createdBy: project.createdBy ?? user!.displayName,
                modified: format(new Date(), 'M/d/yyyy h:mm aa'),
                modifiedBy: user!.displayName,
                draftMode: project.draftMode ?? true,
                stage: project.stage ?? CIProjectStage.focus,
                prettyId: project.prettyId ?? prettyId,
              } as CIProject),
          ),
          purgeUnwantedProps(
            'amEditor',
            'amLead',
            'isMyTeam',
            'isFavorite',
            'milestones',
            'isSelected',
          ),
          map(project => ciProjectSavingInitiated({ project, skipUser })),
        ),
      ),
    ),
  );

  saveCIProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ciProjectSavingInitiated),
      mergeMap(({ project, skipUser }) =>
        this.ciProjects
          .save(project)
          .pipe(map(() => ciProjectSaving({ project, skipUser }))),
      ),
    ),
  );

  saveCIProjects$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ciProjectsSaved),
        withLatestFrom(this.store.select(authenticatedUser)),
        filter(([, user]) => !!user),
        mergeMap(([{ projects }, user]) =>
          from(projects).pipe(
            map(
              project =>
                ({
                  ...project,
                  archived: project.archived ?? false,
                  created:
                    project.created ?? format(new Date(), 'M/d/yyyy h:mm aa'),
                  createdBy: project.createdBy ?? user!.displayName,
                  modified: format(new Date(), 'M/d/yyyy h:mm aa'),
                  modifiedBy: user!.displayName,
                  draftMode: project.draftMode ?? true,
                  stage: project.stage ?? CIProjectStage.focus,
                } as CIProject),
            ),
            purgeUnwantedProps(
              'amEditor',
              'amLead',
              'isMyTeam',
              'isFavorite',
              'milestones',
              'isSelected',
            ),
            reduce(
              (updatedProjects: CIProject[], project) => [
                ...updatedProjects,
                project,
              ],
              [],
            ),
            switchMap(projects => this.ciProjects.saveMany(projects)),
          ),
        ),
      ),
    { dispatch: false },
  );

  correlateSavedProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ciProjectSaving),
      mergeMap(({ project, skipUser }) =>
        this.actions$.pipe(
          ofType(ciProjectsAdded, ciProjectsModified),
          map(({ projects, type }) => ({
            isNew: type === ciProjectsAdded.type,
            changed: projects.find(
              changed => changed.prettyId === project.prettyId,
            ),
          })),
          // eslint-disable-next-line rxjs/no-unsafe-first
          first(({ changed }) => !!changed),
          map(({ changed, isNew }) =>
            ciProjectSavedSuccessfully({ project: changed!, isNew, skipUser }),
          ),
        ),
      ),
    ),
  );

  saveCIProjectStage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ciProjectStageSaved),
        mergeMap(({ project, stage }) =>
          this.ciProjects.saveStage(stage, project),
        ),
      ),
    { dispatch: false },
  );

  deleteCIProject$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ciProjectDeleted),
        mergeMap(({ project }) => this.ciProjects.delete(project)),
      ),
    { dispatch: false },
  );

  deleteCIProjects$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ciProjectsDeleted),
        mergeMap(({ projects }) => this.ciProjects.deleteMany(projects)),
      ),
    { dispatch: false },
  );
}
