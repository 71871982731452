import { HttpClientModule } from '@angular/common/http';
import { CIProjectDataEffects } from './store/ci-project/ci-project.effects';
import { FEATURE_NAME, reducer } from './store/feature.state';
import { HLCDataEffects } from './store/hlc/hlc.effects';
import { MilestoneEffects } from './store/milestones/milestone.effects';
import { ProjectDetailDataEffects } from './store/ci-project/project-detail.effects';
import { ProjectFavoriteEffects } from './store/project-favorites/favorite.effects';
import { ProjectGoalDataEffects } from './store/ci-project/project-goal.effects';
import { ProjectHLCDataEffects } from './store/ci-project/project-hlc.effects';
import { ProjectLinkDataEffects } from './store/ci-project/project-link.effects';
import { ProjectTeamMemberDataEffects } from './store/project-team-member/project-team-member-data.effects';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature(FEATURE_NAME, reducer),
    EffectsModule.forFeature([
      CIProjectDataEffects,
      ProjectTeamMemberDataEffects,
      ProjectFavoriteEffects,
      ProjectDetailDataEffects,
      ProjectGoalDataEffects,
      ProjectLinkDataEffects,
      MilestoneEffects,
      HLCDataEffects,
      ProjectHLCDataEffects
    ]),
  ],
})
export class DomainsCIProjectModule {}
