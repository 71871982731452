import { Actions, ofType } from '@ngrx/effects';
import { routerNavigationAction } from '@ngrx/router-store';
import { Action, ActionCreator, Creator } from '@ngrx/store';
import { merge, pipe } from 'rxjs';
import { exhaustMap, map, takeUntil } from 'rxjs/operators';
import { userLoggingOut } from '~ci-portal/core/auth';
import { routeMatches, routeNotMatches } from '~ci-portal/utils/common-utils';
import { EntityService } from './entity-service';

export const setUpEntityChangeWatcherByRoutes = <T>(
  actions$: Actions,
  service: EntityService<T>,
  buildAddedAction: (entities: T[]) => Action,
  buildModifiedAction: (entities: T[]) => Action,
  buildRemovedAction: (entities: T[]) => Action,
  ...routes: (string | RegExp)[]
) =>
  pipe(
    ofType(routerNavigationAction),
    routeMatches(...routes),
    exhaustMap(() =>
      merge(
        service.added$.pipe(map(entities => buildAddedAction(entities))),
        service.modified$.pipe(map(entities => buildModifiedAction(entities))),
        service.removed$.pipe(map(entities => buildRemovedAction(entities))),
      ).pipe(
        takeUntil(
          merge(
            actions$.pipe(ofType(userLoggingOut)),
            actions$.pipe(
              ofType(routerNavigationAction),
              routeNotMatches(...routes),
            ),
          ),
        ),
      ),
    ),
  );

export const setUpEntityChangeWatcherByActions = <T,
  AC extends ActionCreator<string, Creator>[],
  >(
  actions$: Actions,
  service: EntityService<T>,
  buildAddedAction: (entities: T[]) => Action,
  buildModifiedAction: (entities: T[]) => Action,
  buildRemovedAction: (entities: T[]) => Action,
  ...actions: AC
) =>
  pipe(
    ofType(...actions),
    exhaustMap(() =>
      merge(
        service.added$.pipe(map(entities => buildAddedAction(entities))),
        service.modified$.pipe(map(entities => buildModifiedAction(entities))),
        service.removed$.pipe(map(entities => buildRemovedAction(entities))),
      ).pipe(takeUntil(actions$.pipe(ofType(userLoggingOut)))),
    ),
  );
