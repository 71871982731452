import { Action, createReducer, on } from '@ngrx/store';
import { blockingSpinnerHidden, blockingSpinnerShown } from './blocking-spinner.actions';

export interface BlockingSpinnerState {
  shown: boolean;
  title?: string;
}

export const initialBlockingSpinnerState: BlockingSpinnerState = {
  shown: false,
};

const reduce = createReducer(
  initialBlockingSpinnerState,
  on(
    blockingSpinnerShown,
    (state, { title }): BlockingSpinnerState => ({ shown: true, title }),
  ),
  on(
    blockingSpinnerHidden,
    (): BlockingSpinnerState => ({ shown: false, title: undefined }),
  ),
);

export function blockingSpinnerReducer(
  state = initialBlockingSpinnerState,
  action: Action,
): BlockingSpinnerState {
  return reduce(state, action);
}
