import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import {
  blockingSpinnerReducer,
  BlockingSpinnerState,
} from './blocking-spinner.state';
import { navReducer, NavState } from './nav.state';

export const FEATURE_NAME = 'layout';

export const featureState = createFeatureSelector<LayoutState>(FEATURE_NAME);

export interface LayoutState {
  nav: NavState;
  blockingSpinner: BlockingSpinnerState;
}

export const reducer: ActionReducerMap<LayoutState> = {
  nav: navReducer,
  blockingSpinner: blockingSpinnerReducer,
};
