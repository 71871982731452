import { Pipe, PipeTransform } from '@angular/core';
import { SiteModule } from '../site-modules';
import { findCurrentModule } from '../utils/modules';

@Pipe({ name: 'currentModule' })
export class CurrentModulePipe implements PipeTransform {
  transform(modules?: SiteModule[], currentUrl?: string | null): SiteModule | undefined | null {
    return modules && findCurrentModule(modules, currentUrl);
  };
}
