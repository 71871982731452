import { createSelector } from '@ngrx/store';
import {
  EntityState,
  getEntities,
  getIds,
  renderEntitiesAsArray,
} from '~ci-portal/utils/entity-utils';
import { OrgUnitArea } from '../../models';
import { featureState, FeatureState } from '../feature.state';

export const getOrgUnitAreaState = (
  state: FeatureState,
): EntityState<OrgUnitArea> => state.orgUnitAreas;

export const orgUnitAreaState = createSelector(
  featureState,
  getOrgUnitAreaState,
);

export const orgUnitAreaEntities = createSelector(
  orgUnitAreaState,
  getEntities,
);

export const orgUnitAreaIds = createSelector(orgUnitAreaState, getIds);

export const allOrgUnitAreas = createSelector(
  orgUnitAreaEntities,
  orgUnitAreaIds,
  renderEntitiesAsArray,
);

export const sortedOrgUnitAreas = createSelector(
  allOrgUnitAreas,
  (areas: OrgUnitArea[]) =>
    areas.sort((a, b) =>
      a.title.localeCompare(b.title, undefined, { numeric: true }),
    ),
);
