import { Action, createFeatureSelector, createReducer, on } from '@ngrx/store';
import { applyReductions } from '~ci-portal/utils/common-utils';
import { AuthenticatedUser } from '../models/authenticated-user.model';
import {
  authenticationFailed,
  loggedInUserNotFound,
  loggedInUserReloaded,
  loggedInUserVerificationFailed,
  userAuthenticated,
  userLoggedOut,
  userLoggingIn,
  userLogInCanceled,
} from './auth.actions';

export const AUTH_FEATURE = 'auth';

export const authFeature = createFeatureSelector<AuthState>(AUTH_FEATURE);

export interface AuthState {
  authenticatedUser?: AuthenticatedUser;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
}

export const initialAuthState: AuthState = {
  isAuthenticated: false,
  isAuthenticating: false,
};

export const flagIsAuthenticating = (state: AuthState): AuthState => ({
  ...state,
  isAuthenticating: true,
});

export const unflagIsAuthenticating = (state: AuthState): AuthState => ({
  ...state,
  isAuthenticating: false,
});

export const flagIsAuthenticated = (state: AuthState): AuthState => ({
  ...state,
  isAuthenticated: true,
});

export const unflagIsAuthenticated = (state: AuthState): AuthState => ({
  ...state,
  isAuthenticated: false,
});

export const setAuthenticatedUser = (
  state: AuthState,
  { user }: { user: AuthenticatedUser },
): AuthState => ({
  ...state,
  authenticatedUser: user,
});

export const clearAuthenticatedUser = (state: AuthState): AuthState => ({
  ...state,
  authenticatedUser: undefined,
});

export const resetAuthState = () => initialAuthState;

export const reduce = createReducer(
  initialAuthState,

  on(userLoggingIn, flagIsAuthenticating),
  on(userLoggedOut, resetAuthState),
  on(userLogInCanceled, resetAuthState),
  on(
    userAuthenticated,
    loggedInUserReloaded,
    applyReductions(
      setAuthenticatedUser,
      flagIsAuthenticated,
      unflagIsAuthenticating,
    ),
  ),
  on(
    authenticationFailed,
    loggedInUserNotFound,
    loggedInUserVerificationFailed,
    applyReductions(
      unflagIsAuthenticating,
      unflagIsAuthenticated,
      clearAuthenticatedUser,
    ),
  ),
);

export function authReducer(
  state = initialAuthState,
  action: Action,
): AuthState {
  return reduce(state, action);
}
