export function compose<TInitParam, TResult>(
  f: (ip: TInitParam) => TResult,
): (param: TInitParam) => TResult;
export function compose<TInitParam, TParam2, TResult>(
  f1: (ip: TInitParam) => TParam2,
  f2: (ip: TParam2) => TResult,
): (param: TInitParam) => TResult;
export function compose<TInitParam, TParam2, TParam3, TResult>(
  f1: (ip: TInitParam) => TParam2,
  f2: (ip: TParam2) => TParam3,
  f3: (ip: TParam3) => TResult,
): (param: TInitParam) => TResult;
export function compose<TInitParam, TParam2, TParam3, TParam4, TResult>(
  f1: (ip: TInitParam) => TParam2,
  f2: (ip: TParam2) => TParam3,
  f3: (ip: TParam3) => TParam4,
  f4: (ip: TParam4) => TResult,
): (param: TInitParam) => TResult;
export function compose<TInitParam, TParam2, TParam3, TParam4, TParam5, TResult>(
  f1: (ip: TInitParam) => TParam2,
  f2: (ip: TParam2) => TParam3,
  f3: (ip: TParam3) => TParam4,
  f4: (ip: TParam4) => TParam5,
  f5: (ip: TParam5) => TResult,
): (param: TInitParam) => TResult;
export function compose<TInitParam, TParam2, TParam3, TParam4, TParam5, TParam6, TResult>(
  f1: (ip: TInitParam) => TParam2,
  f2: (ip: TParam2) => TParam3,
  f3: (ip: TParam3) => TParam4,
  f4: (ip: TParam4) => TParam5,
  f5: (ip: TParam5) => TParam6,
  f6: (ip: TParam6) => TResult,
): (param: TInitParam) => TResult;
export function compose<TInitParam, TParam2, TParam3, TParam4, TParam5, TParam6, TParam7, TResult>(
  f1: (ip: TInitParam) => TParam2,
  f2: (ip: TParam2) => TParam3,
  f3: (ip: TParam3) => TParam4,
  f4: (ip: TParam4) => TParam5,
  f5: (ip: TParam5) => TParam6,
  f6: (ip: TParam6) => TParam7,
  f7: (ip: TParam7) => TResult,
): (param: TInitParam) => TResult;
export function compose<TInitParam, TParam2, TParam3, TParam4, TParam5, TParam6, TParam7, TParam8, TResult>(
  f1: (ip: TInitParam) => TParam2,
  f2: (ip: TParam2) => TParam3,
  f3: (ip: TParam3) => TParam4,
  f4: (ip: TParam4) => TParam5,
  f5: (ip: TParam5) => TParam6,
  f6: (ip: TParam6) => TParam7,
  f7: (ip: TParam7) => TParam8,
  f8: (ip: TParam8) => TResult,
): (param: TInitParam) => TResult;
export function compose<TInitParam, TParam2, TParam3, TParam4, TParam5, TParam6, TParam7, TParam8, TParam9, TResult>(
  f1: (ip: TInitParam) => TParam2,
  f2: (ip: TParam2) => TParam3,
  f3: (ip: TParam3) => TParam4,
  f4: (ip: TParam4) => TParam5,
  f5: (ip: TParam5) => TParam6,
  f6: (ip: TParam6) => TParam7,
  f7: (ip: TParam7) => TParam8,
  f8: (ip: TParam8) => TParam9,
  f9: (ip: TParam9) => TResult,
): (param: TInitParam) => TResult;
export function compose<TInitParam, TParam2, TParam3, TParam4, TParam5, TParam6, TParam7, TParam8, TParam9, TParam10, TResult>(
  f1: (ip: TInitParam) => TParam2,
  f2: (ip: TParam2) => TParam3,
  f3: (ip: TParam3) => TParam4,
  f4: (ip: TParam4) => TParam5,
  f5: (ip: TParam5) => TParam6,
  f6: (ip: TParam6) => TParam7,
  f7: (ip: TParam7) => TParam8,
  f8: (ip: TParam8) => TParam9,
  f9: (ip: TParam9) => TParam10,
  f10: (ip: TParam10) => TResult,
): (param: TInitParam) => TResult;
export function compose<TInitParam, TParam2, TParam3, TParam4, TParam5, TParam6, TParam7, TParam8, TParam9, TParam10, TParam11, TResult>(
  f1: (ip: TInitParam) => TParam2,
  f2: (ip: TParam2) => TParam3,
  f3: (ip: TParam3) => TParam4,
  f4: (ip: TParam4) => TParam5,
  f5: (ip: TParam5) => TParam6,
  f6: (ip: TParam6) => TParam7,
  f7: (ip: TParam7) => TParam8,
  f8: (ip: TParam8) => TParam9,
  f9: (ip: TParam9) => TParam10,
  f10: (ip: TParam10) => TParam11,
  f11: (ip: TParam11) => TResult,
): (param: TInitParam) => TResult;
export function compose<TInitParam, TParam2, TParam3, TParam4, TParam5, TParam6, TParam7, TParam8, TParam9, TParam10, TParam11, TParam12, TResult>(
  f1: (ip: TInitParam) => TParam2,
  f2: (ip: TParam2) => TParam3,
  f3: (ip: TParam3) => TParam4,
  f4: (ip: TParam4) => TParam5,
  f5: (ip: TParam5) => TParam6,
  f6: (ip: TParam6) => TParam7,
  f7: (ip: TParam7) => TParam8,
  f8: (ip: TParam8) => TParam9,
  f9: (ip: TParam9) => TParam10,
  f10: (ip: TParam10) => TParam11,
  f11: (ip: TParam11) => TParam12,
  f12: (ip: TParam12) => TResult,
): (param: TInitParam) => TResult;
export function compose<TInitParam, TParam2, TParam3, TParam4, TParam5, TParam6, TParam7, TParam8, TParam9, TParam10, TParam11, TParam12, TParam13, TResult>(
  f1: (ip: TInitParam) => TParam2,
  f2: (ip: TParam2) => TParam3,
  f3: (ip: TParam3) => TParam4,
  f4: (ip: TParam4) => TParam5,
  f5: (ip: TParam5) => TParam6,
  f6: (ip: TParam6) => TParam7,
  f7: (ip: TParam7) => TParam8,
  f8: (ip: TParam8) => TParam9,
  f9: (ip: TParam9) => TParam10,
  f10: (ip: TParam10) => TParam11,
  f11: (ip: TParam11) => TParam12,
  f12: (ip: TParam12) => TParam13,
  f13: (ip: TParam13) => TResult,
): (param: TInitParam) => TResult;
export function compose<TInitParam, TParam2, TParam3, TParam4, TParam5, TParam6, TParam7, TParam8, TParam9, TParam10, TParam11, TParam12, TParam13, TParam14, TResult>(
  f1: (ip: TInitParam) => TParam2,
  f2: (ip: TParam2) => TParam3,
  f3: (ip: TParam3) => TParam4,
  f4: (ip: TParam4) => TParam5,
  f5: (ip: TParam5) => TParam6,
  f6: (ip: TParam6) => TParam7,
  f7: (ip: TParam7) => TParam8,
  f8: (ip: TParam8) => TParam9,
  f9: (ip: TParam9) => TParam10,
  f10: (ip: TParam10) => TParam11,
  f11: (ip: TParam11) => TParam12,
  f12: (ip: TParam12) => TParam13,
  f13: (ip: TParam13) => TParam14,
  f14: (ip: TParam14) => TResult,
): (param: TInitParam) => TResult;
export function compose<TInitParam, TParam2, TParam3, TParam4, TParam5, TParam6, TParam7, TParam8, TParam9, TParam10, TParam11, TParam12, TParam13, TParam14, TParam15, TResult>(
  f1: (ip: TInitParam) => TParam2,
  f2: (ip: TParam2) => TParam3,
  f3: (ip: TParam3) => TParam4,
  f4: (ip: TParam4) => TParam5,
  f5: (ip: TParam5) => TParam6,
  f6: (ip: TParam6) => TParam7,
  f7: (ip: TParam7) => TParam8,
  f8: (ip: TParam8) => TParam9,
  f9: (ip: TParam9) => TParam10,
  f10: (ip: TParam10) => TParam11,
  f11: (ip: TParam11) => TParam12,
  f12: (ip: TParam12) => TParam13,
  f13: (ip: TParam13) => TParam14,
  f14: (ip: TParam14) => TParam15,
  f15: (ip: TParam15) => TResult,
): (param: TInitParam) => TResult;
export function compose<TInitParam, TParam2, TParam3, TParam4, TParam5, TParam6, TParam7, TParam8, TParam9, TParam10, TParam11, TParam12, TParam13, TParam14, TParam15, TParam16, TResult>(
  f1: (ip: TInitParam) => TParam2,
  f2: (ip: TParam2) => TParam3,
  f3: (ip: TParam3) => TParam4,
  f4: (ip: TParam4) => TParam5,
  f5: (ip: TParam5) => TParam6,
  f6: (ip: TParam6) => TParam7,
  f7: (ip: TParam7) => TParam8,
  f8: (ip: TParam8) => TParam9,
  f9: (ip: TParam9) => TParam10,
  f10: (ip: TParam10) => TParam11,
  f11: (ip: TParam11) => TParam12,
  f12: (ip: TParam12) => TParam13,
  f13: (ip: TParam13) => TParam14,
  f14: (ip: TParam14) => TParam15,
  f15: (ip: TParam15) => TParam16,
  f16: (ip: TParam16) => TResult,
): (param: TInitParam) => TResult;
export function compose<TInitParam, TParam2, TParam3, TParam4, TParam5, TParam6, TParam7, TParam8, TParam9, TParam10, TParam11, TParam12, TParam13, TParam14, TParam15, TParam16, TParam17, TResult>(
  f1: (ip: TInitParam) => TParam2,
  f2: (ip: TParam2) => TParam3,
  f3: (ip: TParam3) => TParam4,
  f4: (ip: TParam4) => TParam5,
  f5: (ip: TParam5) => TParam6,
  f6: (ip: TParam6) => TParam7,
  f7: (ip: TParam7) => TParam8,
  f8: (ip: TParam8) => TParam9,
  f9: (ip: TParam9) => TParam10,
  f10: (ip: TParam10) => TParam11,
  f11: (ip: TParam11) => TParam12,
  f12: (ip: TParam12) => TParam13,
  f13: (ip: TParam13) => TParam14,
  f14: (ip: TParam14) => TParam15,
  f15: (ip: TParam15) => TParam16,
  f16: (ip: TParam16) => TParam17,
  f17: (ip: TParam17) => TResult,
): (param: TInitParam) => TResult;
export function compose<TInitParam, TParam2, TParam3, TParam4, TParam5, TParam6, TParam7, TParam8, TParam9, TParam10, TParam11, TParam12, TParam13, TParam14, TParam15, TParam16, TParam17, TParam18, TResult>(
  f1: (ip: TInitParam) => TParam2,
  f2: (ip: TParam2) => TParam3,
  f3: (ip: TParam3) => TParam4,
  f4: (ip: TParam4) => TParam5,
  f5: (ip: TParam5) => TParam6,
  f6: (ip: TParam6) => TParam7,
  f7: (ip: TParam7) => TParam8,
  f8: (ip: TParam8) => TParam9,
  f9: (ip: TParam9) => TParam10,
  f10: (ip: TParam10) => TParam11,
  f11: (ip: TParam11) => TParam12,
  f12: (ip: TParam12) => TParam13,
  f13: (ip: TParam13) => TParam14,
  f14: (ip: TParam14) => TParam15,
  f15: (ip: TParam15) => TParam16,
  f16: (ip: TParam16) => TParam17,
  f17: (ip: TParam17) => TParam18,
  f18: (ip: TParam18) => TResult,
): (param: TInitParam) => TResult;
export function compose<TInitParam, TParam2, TParam3, TParam4, TParam5, TParam6, TParam7, TParam8, TParam9, TParam10, TParam11, TParam12, TParam13, TParam14, TParam15, TParam16, TParam17, TParam18, TParam19, TResult>(
  f1: (ip: TInitParam) => TParam2,
  f2: (ip: TParam2) => TParam3,
  f3: (ip: TParam3) => TParam4,
  f4: (ip: TParam4) => TParam5,
  f5: (ip: TParam5) => TParam6,
  f6: (ip: TParam6) => TParam7,
  f7: (ip: TParam7) => TParam8,
  f8: (ip: TParam8) => TParam9,
  f9: (ip: TParam9) => TParam10,
  f10: (ip: TParam10) => TParam11,
  f11: (ip: TParam11) => TParam12,
  f12: (ip: TParam12) => TParam13,
  f13: (ip: TParam13) => TParam14,
  f14: (ip: TParam14) => TParam15,
  f15: (ip: TParam15) => TParam16,
  f16: (ip: TParam16) => TParam17,
  f17: (ip: TParam17) => TParam18,
  f18: (ip: TParam18) => TParam19,
  f19: (ip: TParam19) => TResult,
): (param: TInitParam) => TResult;
export function compose<TInitParam, TParam2, TParam3, TParam4, TParam5, TParam6, TParam7, TParam8, TParam9, TParam10, TParam11, TParam12, TParam13, TParam14, TParam15, TParam16, TParam17, TParam18, TParam19, TParam20, TResult>(
  f1: (ip: TInitParam) => TParam2,
  f2: (ip: TParam2) => TParam3,
  f3: (ip: TParam3) => TParam4,
  f4: (ip: TParam4) => TParam5,
  f5: (ip: TParam5) => TParam6,
  f6: (ip: TParam6) => TParam7,
  f7: (ip: TParam7) => TParam8,
  f8: (ip: TParam8) => TParam9,
  f9: (ip: TParam9) => TParam10,
  f10: (ip: TParam10) => TParam11,
  f11: (ip: TParam11) => TParam12,
  f12: (ip: TParam12) => TParam13,
  f13: (ip: TParam13) => TParam14,
  f14: (ip: TParam14) => TParam15,
  f15: (ip: TParam15) => TParam16,
  f16: (ip: TParam16) => TParam17,
  f17: (ip: TParam17) => TParam18,
  f18: (ip: TParam18) => TParam19,
  f19: (ip: TParam19) => TParam20,
  f20: (ip: TParam20) => TResult,
): (param: TInitParam) => TResult;

// eslint-disable-next-line @typescript-eslint/ban-types
export function compose<TParam, TResult>(...fns: Function[]) {
  return (initialParameter: TParam): TResult =>
    fns.reduce((param, fn) => fn(param), initialParameter) as unknown as TResult;
}
