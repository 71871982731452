import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap } from 'rxjs/operators';
import { loggedInUserVerified, userAuthenticated } from '~ci-portal/core/auth';
import { setUpEntityChangeWatcherByActions } from '~ci-portal/utils/entity-utils';
import {
  orgUnitAreaDeleted,
  orgUnitAreasAdded,
  orgUnitAreaSaved,
  orgUnitAreasModified,
  orgUnitAreasRemoved,
} from './org-unit-area.actions';
import { OrgUnitAreaService } from './org-unit-area.service';

@Injectable({ providedIn: 'root' })
export class OrgUnitAreaDataEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly orgUnitAreas: OrgUnitAreaService,
  ) {
  }

  watchEntityChanges$ = createEffect(() =>
    this.actions$.pipe(
      setUpEntityChangeWatcherByActions(
        this.actions$,
        this.orgUnitAreas,
        areas => orgUnitAreasAdded({ areas }),
        areas => orgUnitAreasModified({ areas }),
        areas => orgUnitAreasRemoved({ areas }),
        userAuthenticated,
        loggedInUserVerified,
      ),
    ),
  );

  deleteOrgUnitArea$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(orgUnitAreaDeleted),
        mergeMap(({ area }) => this.orgUnitAreas.delete(area)),
      ), { dispatch: false },
  );

  saveOrgUnitArea$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(orgUnitAreaSaved),
        mergeMap(({ area }) => this.orgUnitAreas.save(area)),
      ), { dispatch: false },
  );
}
