<div
  class="d-flex justify-content-center align-items-center"
  *ngIf="shown$ | async"
>
  <div
    class="
      spinner-box
      d-flex
      w-25
      h-25
      flex-column
      justify-content-center
      align-items-center
    "
  >
    <mat-progress-spinner
      mode="indeterminate"
      [strokeWidth]="2"
    ></mat-progress-spinner>
    <div class="mt-2">{{ title$ | async }}</div>
  </div>
</div>
