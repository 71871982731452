import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, mergeEntities, removeEntities } from '~ci-portal/utils/entity-utils';
import { Strategy } from '../../models';
import {
  strategiesAdded,
  strategiesModified,
  strategiesRemoved,
} from './strategy.actions';

export const initialStrategyState: EntityState<Strategy> = {
  entities: {},
  ids: [],
  pendingInitialLoad: true,
};

export const mergeStrategies = (
  state: EntityState<Strategy>,
  { strategies }: { strategies: Strategy[] },
) => mergeEntities(state, strategies, 'key');

export const removeStrategies = (
  state: EntityState<Strategy>,
  { strategies }: { strategies: Strategy[] },
) => removeEntities(state, strategies, 'key');

export const reduce = createReducer(
  initialStrategyState,
  on(strategiesAdded, strategiesModified, mergeStrategies),
  on(strategiesRemoved, removeStrategies),
);

export function strategyReducer(
  state = initialStrategyState,
  action: Action,
): EntityState<Strategy> {
  return reduce(state, action);
}
