import { createSelector } from '@ngrx/store';
import {
  EntityState,
  getEntities,
  getIds,
  renderEntitiesAsArray,
} from '~ci-portal/utils/entity-utils';
import { featureState, FeatureState } from '../feature.state';
import { User } from '../models/user.model';

export const getUserState = (state: FeatureState): EntityState<User> =>
  state.users;

export const userState = createSelector(featureState, getUserState);

export const userEntities = createSelector(userState, getEntities);

export const userIds = createSelector(userState, getIds);

export const allUsers = createSelector(
  userEntities,
  userIds,
  renderEntitiesAsArray,
);

export const sortedUsers = createSelector(allUsers, (users: User[]) =>
  users.sort((a, b) =>
    (a.displayName ?? a.email).localeCompare(
      b.displayName ?? b.email,
      undefined,
      { numeric: true },
    ),
  ),
);
