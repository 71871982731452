import { createSelector } from '@ngrx/store';
import {
  EntityState,
  getEntities,
  getIds,
  renderEntitiesAsArray,
} from '~ci-portal/utils/entity-utils';
import { Goal } from '../../models';
import { featureState, FeatureState } from '../feature.state';

export const getGoalState = (state: FeatureState): EntityState<Goal> =>
  state.goals;

export const goalState = createSelector(featureState, getGoalState);

export const goalEntities = createSelector(goalState, getEntities);

export const goalIds = createSelector(goalState, getIds);

export const allGoals = createSelector(
  goalEntities,
  goalIds,
  renderEntitiesAsArray,
);

export const sortedGoals = createSelector(allGoals, (goals: Goal[]) =>
  goals.sort((a, b) => a.order - b.order),
);
