import moize from 'moize';

export interface HasPGSIds {
  id: string;
  priorityId: string;
  goalId?: string;
}

export const buildCompositeId = moize((hasIds: HasPGSIds, pad = false) =>
  hasIds.goalId
    ? `${hasIds.priorityId}-${hasIds.goalId}-${hasIds.id}`
    : `${hasIds.priorityId}-${hasIds.id}${pad ? `-00` : ''}`,
);

export const isToggled = (hasIds: HasPGSIds, toggledIds: string[]): boolean =>
  toggledIds.includes(buildCompositeId(hasIds));
