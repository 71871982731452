import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { from, Observable } from 'rxjs';
import { SmartFavorite } from '../../models';

const COLLECTION_NAME = 'spFavorites';

@Injectable({ providedIn: 'any' })
export class SmartFavoritesService {
  private readonly collection: AngularFirestoreCollection<SmartFavorite>;

  constructor(private readonly firestore: AngularFirestore) {
    this.collection = firestore.collection<SmartFavorite>(COLLECTION_NAME);
  }

  byEmail(email: string): Observable<SmartFavorite[]> {
    return from(this.firestore
      .collection<SmartFavorite>(COLLECTION_NAME, ref =>
        ref.where('email', '==', email),
      )
      .valueChanges());
  }

  addFavorite(favorite: SmartFavorite): Observable<void> {
    return from(this.collection.doc(favorite.id).set(favorite));
  }

  removeFavorite(favorite: SmartFavorite): Observable<void> {
    return from(this.collection.doc(favorite.id).delete());
  }
}
