import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CIProjectService } from './ci-project.service';

@Injectable({ providedIn: 'root' })
export class CIProjectPublicService {
  constructor(private readonly ciProjects: CIProjectService) {}

  projectExists(projectId: string): Observable<boolean> {
    return this.ciProjects.projectExists(projectId);
  }
}
