import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { StoreModule } from '@ngrx/store';
import { PushModule } from '@rx-angular/template';
import { AuthModule } from '~ci-portal/core/auth';
import { AuthenticatedUserBadgeComponent } from './components/authenticated-user-badge/authenticated-user-badge.component';
import { BlockingSpinnerComponent } from './components/blocking-spinner/blocking-spinner.component';
import { GlobalLinksComponent } from './components/global-links/global-links.component';
import { SiteHeaderComponent } from './components/site-header/site-header.component';
import { SiteMenuComponent } from './components/site-menu/site-menu.component';
import { SiteNavComponent } from './components/site-nav/site-nav.component';
import { CurrentModulePipe } from './pipes/current-module.pipe';
import { IsActiveAreaPipe } from './pipes/is-active-area.pipe';
import { IsActiveModulePipe } from './pipes/is-active-module.pipe';
import { OnlyAuthorizedModulesPipe } from './pipes/only-authorized-modules.pipe';
import { OnlyVisibleModulesPipe } from './pipes/only-visible-modules.pipe';
import { FEATURE_NAME, reducer } from './store/feature';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    PushModule,
    StoreModule.forFeature(FEATURE_NAME, reducer),
    AuthModule,
    MatProgressSpinnerModule,
  ],
  declarations: [
    AuthenticatedUserBadgeComponent,
    GlobalLinksComponent,
    SiteMenuComponent,
    SiteNavComponent,
    SiteHeaderComponent,
    CurrentModulePipe,
    IsActiveModulePipe,
    IsActiveAreaPipe,
    OnlyVisibleModulesPipe,
    OnlyAuthorizedModulesPipe,
    BlockingSpinnerComponent,
  ],
  exports: [SiteHeaderComponent, BlockingSpinnerComponent],
})
export class ComponentsLayoutModule {}
