import { createSelector } from '@ngrx/store';
import {
  EntityState,
  getEntities,
  getIds,
  getPendingInitialLoadFlag,
  renderEntitiesAsArray,
} from '~ci-portal/utils/entity-utils';
import { HLC } from '../../models';
import { featureState, FeatureState } from '../feature.state';

export const getHLCState = (state: FeatureState): EntityState<HLC> =>
  state.hlcs;

export const hlcState = createSelector(featureState, getHLCState);

export const hlcEntities = createSelector(hlcState, getEntities);

export const hlcIds = createSelector(hlcState, getIds);

export const allHLCs = createSelector(
  hlcEntities,
  hlcIds,
  renderEntitiesAsArray,
);

export const hlcPending = createSelector(hlcState, getPendingInitialLoadFlag);
