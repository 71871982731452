import { EMPTY, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

export interface IndirectReference {
  key: string;
}

export type ReferenceMap<T> = {
  [key: string]: T | undefined;
};

export class IndirectReferenceManager<T> {
  private refMap: ReferenceMap<T> = {};

  create(
    instance: T,
    destroyOn: Observable<unknown> = EMPTY,
  ): Readonly<IndirectReference> {
    const ref: IndirectReference = {
      key: uuid(),
    };

    this.refMap[ref.key] = instance;

    if (destroyOn !== EMPTY) {
      destroyOn
        .pipe(take(1))
        .subscribe(() => (this.refMap[ref.key] = undefined));
    }

    return ref;
  }

  destroy(ref?: Readonly<IndirectReference>): void {
    ref ? (this.refMap[ref.key] = undefined) : void 0;
  }

  resolve(ref: Readonly<IndirectReference>): T | undefined {
    return this.refMap[ref.key];
  }
}
