import { createAction, props } from '@ngrx/store';
import { Goal } from '../../models';

export const goalsLoaded = createAction(
  '[STORE: Planning] (Goals) Loaded',
  props<{ goals: Goal[] }>(),
);

export const goalsAdded = createAction(
  '[STORE: Planning] (Goals) Added',
  props<{ goals: Goal[] }>(),
);

export const goalsModified = createAction(
  '[STORE: Planning] (Goals) Modified',
  props<{ goals: Goal[] }>(),
);

export const goalsRemoved = createAction(
  '[STORE: Planning] (Goals) Removed',
  props<{ goals: Goal[] }>(),
);

export const goalSaved = createAction(
  '[STORE: Planning] (Goal) Saved',
  props<{ goal: Goal }>(),
)

export const goalDeleted = createAction(
  '[STORE: Planning] (Goal) Deleted',
  props<{ goal: Goal }>(),
);
