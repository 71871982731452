import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigationAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import {
  filter,
  first,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { AuthenticatedUser, authenticatedUser } from '~ci-portal/core/auth';
import {
  routeMatchesPath,
  routeNotMatchesPath,
} from '~ci-portal/utils/common-utils';
import { setUpEntityChangeWatcherByRoutes } from '~ci-portal/utils/entity-utils';
import { ProjectTeamMember } from '../../models';
import { ciProjectSavedSuccessfully } from '../ci-project/ci-project.public.actions';
import {
  myProjectTeamMembersUnwatched,
  myProjectTeamMembersWatched,
  projectTeamMemberDeleted,
  projectTeamMembersAdded,
  projectTeamMemberSaved,
  projectTeamMemberSavedSuccessfully,
  projectTeamMemberSaving,
  projectTeamMembersChanged,
  projectTeamMembersModified,
  projectTeamMembersRemoved,
} from './project-team-member.actions';
import { allProjectTeamMembers } from './project-team-member.selectors';
import { ProjectTeamMemberService } from './project-team-member.service';

@Injectable()
export class ProjectTeamMemberDataEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly projectTeamMembers: ProjectTeamMemberService,
  ) {}

  watchMyProjectTeamMembers = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigationAction),
      routeMatchesPath('/'),
      switchMap(() => this.store.select(authenticatedUser)),
      filter((user?: AuthenticatedUser): user is AuthenticatedUser => !!user),
      map(() => myProjectTeamMembersWatched()),
    ),
  );

  unwatchMyProjectTeamMembers = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigationAction),
      routeNotMatchesPath('/'),
      map(() => myProjectTeamMembersUnwatched()),
    ),
  );

  updateTeamMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(projectTeamMemberSaved),
      mergeMap(({ teamMember }) =>
        this.projectTeamMembers
          .save(teamMember)
          .pipe(map(() => projectTeamMemberSaving({ teamMember }))),
      ),
    ),
  );

  trackMyProjectTeamMembers = createEffect(() =>
    this.actions$.pipe(
      ofType(myProjectTeamMembersWatched),
      switchMap(() =>
        this.store.select(authenticatedUser).pipe(
          filter(
            (user?: AuthenticatedUser): user is AuthenticatedUser => !!user,
          ),
          switchMap(user =>
            this.projectTeamMembers.byEmail(user.email).pipe(
              map(teamMembers => projectTeamMembersChanged({ teamMembers })),
              takeUntil(
                this.actions$.pipe(ofType(myProjectTeamMembersUnwatched)),
              ),
            ),
          ),
          takeUntil(this.actions$.pipe(ofType(myProjectTeamMembersUnwatched))),
        ),
      ),
    ),
  );

  correlateSavedTeamMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(projectTeamMemberSaving),
      mergeMap(({ teamMember }) =>
        this.actions$.pipe(
          ofType(projectTeamMembersAdded, projectTeamMembersModified),
          map(({ teamMembers }) =>
            teamMembers.find(changed => changed.id === teamMember.id),
          ),
          // eslint-disable-next-line rxjs/no-unsafe-first
          first(
            (changed?: ProjectTeamMember): changed is ProjectTeamMember =>
              !!changed,
          ),
          map(changed =>
            projectTeamMemberSavedSuccessfully({ teamMember: changed }),
          ),
        ),
      ),
    ),
  );

  deleteTeamMember$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(projectTeamMemberDeleted),
        mergeMap(({ teamMember }) =>
          this.projectTeamMembers.delete(teamMember),
        ),
      ),
    { dispatch: false },
  );

  addCurrentUserToProjectTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ciProjectSavedSuccessfully),
      filter(({ isNew, skipUser }) => isNew === true && skipUser !== true),
      withLatestFrom(
        this.store.select(authenticatedUser),
        this.store.select(allProjectTeamMembers),
      ),
      filter(
        ([{ project }, user, members]) =>
          !!user &&
          !(
            members?.some(
              member =>
                member.projectId === project.projectId &&
                member.email === user.email,
            ) ?? false
          ),
      ),
      map(([{ project }, user]) => ({
        name: user!.displayName ?? user!.email,
        email: user!.email,
        edit: true,
        lead: false,
        projectId: project.projectId,
        id: uuidv4(),
      })),
      map(teamMember => projectTeamMemberSaved({ teamMember })),
    ),
  );

  watchEntityChanges$ = createEffect(() =>
    this.actions$.pipe(
      setUpEntityChangeWatcherByRoutes(
        this.actions$,
        this.projectTeamMembers,
        teamMembers => projectTeamMembersAdded({ teamMembers }),
        teamMembers => projectTeamMembersModified({ teamMembers }),
        teamMembers => projectTeamMembersRemoved({ teamMembers }),
        '/ci-projects/list',
        '/ci-projects/create',
        '/ci-projects/edit',
        '/ci-projects/report',
      ),
    ),
  );
}
