import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  routerNavigatedAction,
  routerNavigationAction,
} from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import moize from 'moize';
import { merge } from 'rxjs';
import {
  exhaustMap,
  filter,
  first,
  map, mergeMap,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import {
  AuthenticatedUser,
  authenticatedUser,
  userLoggingOut,
} from '~ci-portal/core/auth';
import {
  routeNotStartsWithPath,
  routeStartsWithPath,
  sha1,
} from '~ci-portal/utils/common-utils';
import { SmartFavorite } from '../../models/smart-favorite.model';
import {
  smartFavoritesChanged,
  smartFavoriteToggling,
} from './smart-favorites.actions';
import { allFavorites } from './smart-favorites.selectors';
import { SmartFavoritesService } from './smart-favorites.service';

export const formatFavoriteId = moize(
  (orgUnitId: string, email: string) => `${orgUnitId}-${sha1(email)}`,
);

export const whereFavoriteOrgAndEmailMatch = (orgUnitId: string, email?: string) => (fav: SmartFavorite) => fav.orgUnitId === orgUnitId && fav.email === email

@Injectable()
export class SmartFavoritesEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly favorites: SmartFavoritesService,
  ) {}

  preloadUserFavorites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigationAction),
      routeStartsWithPath(
        '/smart/teams',
        '/smart/team',
        '/smart/area',
      ),
      switchMap(() =>
        this.store.select(authenticatedUser).pipe(first(user => !!user)),
      ),
      filter((user): user is AuthenticatedUser => !!user),
      exhaustMap(({ email }) =>
        this.favorites.byEmail(email).pipe(
          map(favorites => smartFavoritesChanged({ favorites })),
          takeUntil(
            merge(
              this.actions$.pipe(ofType(userLoggingOut)),
              this.actions$.pipe(
                ofType(routerNavigatedAction),
                routeNotStartsWithPath(
                  '/smart/teams',
                  '/smart/team',
                  '/smart/area',
                ),
              ),
            ),
          ),
        ),
      ),
    ),
  );

  favoriteOrgUnit$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(smartFavoriteToggling),
        withLatestFrom(
          this.store.select(authenticatedUser),
          this.store.select(allFavorites)
        ),
        filter(([{ orgUnitId }, user, favorites]) => !!user && !favorites.find(whereFavoriteOrgAndEmailMatch(orgUnitId, user.email))),
        map(([{ orgUnitId }, user]) => ({
          id: uuidv4(),
          orgUnitId: orgUnitId,
          email: user!.email,
          name: user!.displayName ?? user!.email,
        })),
        mergeMap(favorite => this.favorites.addFavorite(favorite)),
      ),
    { dispatch: false },
  );

  unfavoriteOrgUnit$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(smartFavoriteToggling),
        withLatestFrom(
          this.store.select(authenticatedUser),
          this.store.select(allFavorites),
        ),
        filter(([, user]) => !!user),
        map(([{ orgUnitId }, user, favorites]) =>
          favorites.find(
            whereFavoriteOrgAndEmailMatch(orgUnitId, user?.email)
          ),
        ),
        filter(
          (favorite?: SmartFavorite): favorite is SmartFavorite => !!favorite,
        ),
        mergeMap(favorite => this.favorites.removeFavorite(favorite)),
      ),
    { dispatch: false },
  );
}
