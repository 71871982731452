export interface NamedLink {
  uri: string;
  name: string;
  isExternal?: boolean;
}

export const GLOBAL_LINKS: NamedLink[] = [
  { uri: 'https://www.heartland.edu/about/strategicPlan.html', name: 'Planning Guide', isExternal: true },
  { uri: '/resources-definitions', name: 'Resources & Definitions' },
  { uri: '/reports/smart-action-summary', name: 'Reports' },

];
