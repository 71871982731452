import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { from, Observable } from 'rxjs';
import { ProjectFavorite } from '../../models';

const COLLECTION_NAME = 'ciFavorites';

@Injectable({ providedIn: 'any' })
export class ProjectFavoritesService {
  private readonly collection: AngularFirestoreCollection<ProjectFavorite>;

  constructor(private readonly firestore: AngularFirestore) {
    this.collection = firestore.collection<ProjectFavorite>(COLLECTION_NAME);
  }

  byEmail(email: string): Observable<ProjectFavorite[]> {
    return from(this.firestore
      .collection<ProjectFavorite>(COLLECTION_NAME, ref =>
        ref.where('email', '==', email),
      )
      .valueChanges());
  }

  add(favorite: ProjectFavorite): Observable<void> {
    return from(this.collection.doc(favorite.id).set(favorite));
  }

  remove(favorite: ProjectFavorite): Observable<void> {
    return from(this.collection.doc(favorite.id).delete());
  }
}
