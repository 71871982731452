import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs/operators';
import { sha1 } from '~ci-portal/utils/common-utils';
import { setUpEntityChangeWatcherByRoutes } from '~ci-portal/utils/entity-utils';
import {
  unitTeamMemberDeleted,
  unitTeamMemberSaved,
  unitTeamsAdded,
  unitTeamsModified,
  unitTeamsRemoved,
} from './unit-team.actions';
import { UnitTeamsService } from './unit-team.service';

@Injectable()
export class UnitTeamDataEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly unitTeams: UnitTeamsService,
  ) {}

  updateTeamMember$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(unitTeamMemberSaved),
        map(({ teamMember }) => ({
          ...teamMember,
          id: `${teamMember.spOrgUnitID}-${sha1(teamMember.email)}`,
        })),
        mergeMap(teamMember => this.unitTeams.save(teamMember)),
      ),
    { dispatch: false },
  );

  deleteTeamMember$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(unitTeamMemberDeleted),
        mergeMap(({ teamMember }) => this.unitTeams.delete(teamMember)),
      ),
    { dispatch: false },
  );

  watchEntityChanges$ = createEffect(() =>
    this.actions$.pipe(
      setUpEntityChangeWatcherByRoutes(
        this.actions$,
        this.unitTeams,
        teamMembers => unitTeamsAdded({ teamMembers }),
        teamMembers => unitTeamsModified({ teamMembers }),
        teamMembers => unitTeamsRemoved({ teamMembers }),
        '/',
        '/smart/teams',
        '/smart/team',
        '/smart/area',
      ),
    ),
  );
}
