import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap } from 'rxjs/operators';
import { setUpEntityChangeWatcherByRoutes } from '~ci-portal/utils/entity-utils';
import {
  strategiesAdded,
  strategiesModified,
  strategiesRemoved, strategyDeleted, strategySaved,
} from './strategy.actions';
import { StrategyService } from './strategy.service';

@Injectable({ providedIn: 'root' })
export class StrategyDataEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly strategies: StrategyService,
  ) {}

  watchEntityChanges$ = createEffect(() =>
    this.actions$.pipe(
      setUpEntityChangeWatcherByRoutes(
        this.actions$,
        this.strategies,
        strategies => strategiesAdded({ strategies }),
        strategies => strategiesModified({ strategies }),
        strategies => strategiesRemoved({ strategies }),
        '/smart/team',
        '/smart/area',
        '/reports/smart-action-summary',
        '/admin/manage-goals',
        '/admin'
      ),
    ),
  );

  saveStrategy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(strategySaved),
      mergeMap(({ strategy }) => this.strategies.save(strategy)),
    ),
    { dispatch: false },
  );

  deleteStrategy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(strategyDeleted),
      mergeMap(({ strategy }) => this.strategies.delete(strategy)),
    ),
    { dispatch: false },
  );
}
