import { PageEvent } from '@angular/material/paginator';

export interface UIState {
  page?: PageEvent;
}

export const toggleFilter =
  (primary: string, ...others: string[]) =>
  <TState extends UIState>(state: TState): TState => ({
    ...state,
    ...others.reduce(
      (obj: any, other: string) => ((obj[`${other}Filter`] = false), obj),
      {},
    ),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    [`${primary}Filter`]: !state[`${primary}Filter`],
    page: state.page
      ? {
          ...state.page,
          pageIndex: 0,
        }
      : state.page,
  });

export const clearProp =
  <T extends UIState>(prop: keyof T) =>
  (state: T): T => ({
    ...state,
    [prop]: undefined,
  });

export const setCriteria =
  <T>(prop: keyof T) =>
  (state: T, { criteria }: { criteria: string }): T => ({
    ...state,
    [prop]: criteria,
  });
