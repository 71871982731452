import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map } from 'rxjs/operators';
import { CIProjectService } from './ci-project.service';
import {
  projectDetailSaved,
  projectDetailSavedSuccessfully,
} from './project-detail.actions';

@Injectable({ providedIn: 'root' })
export class ProjectDetailDataEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly projects: CIProjectService,
  ) {}

  updateProjectDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(projectDetailSaved),
      exhaustMap(({ project, detail }) =>
        this.projects
          .saveDetails(detail, project)
          .pipe(map(() => projectDetailSavedSuccessfully())),
      ),
    ),
  );
}
