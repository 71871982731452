import {
  RouterNavigatedAction,
  SerializedRouterStateSnapshot,
} from '@ngrx/router-store';
import { pipe } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export const routeMatchesPath = (...urls: string[]) =>
  pipe(
    map((r: RouterNavigatedAction) => r.payload.routerState),
    filter((routerState: SerializedRouterStateSnapshot) =>
      urls.some(url => routerState.url === url),
    ),
  );
export const routeNotMatchesPath = (...urls: string[]) =>
  pipe(
    map((r: RouterNavigatedAction) => r.payload.routerState),
    filter(
      (routerState: SerializedRouterStateSnapshot) =>
        !urls.some(url => routerState.url === url),
    ),
  );

export const routeIncludesPath = (...urls: string[]) =>
  pipe(
    map((r: RouterNavigatedAction) => r.payload.routerState),
    filter((routerState: SerializedRouterStateSnapshot) =>
      urls.some(url => routerState.url.includes(url)),
    ),
  );
export const routeNotIncludesPath = (...urls: string[]) =>
  pipe(
    map((r: RouterNavigatedAction) => r.payload.routerState),
    filter(
      (routerState: SerializedRouterStateSnapshot) =>
        !urls.some(url => routerState.url.includes(url)),
    ),
  );

export const routeEndsInPath = (...urls: string[]) =>
  pipe(
    map((r: RouterNavigatedAction) => r.payload.routerState),
    filter((routerState: SerializedRouterStateSnapshot) =>
      urls.some(url => routerState.url.endsWith(url)),
    ),
  );
export const routeNotEndsInPath = (...urls: string[]) =>
  pipe(
    map((r: RouterNavigatedAction) => r.payload.routerState),
    filter(
      (routerState: SerializedRouterStateSnapshot) =>
        !urls.some(url => routerState.url.endsWith(url)),
    ),
  );

export const routeStartsWithPath = (...urls: string[]) =>
  pipe(
    map((r: RouterNavigatedAction) => r.payload.routerState),
    filter((routerState: SerializedRouterStateSnapshot) =>
      urls.some(url => routerState.url.startsWith(url)),
    ),
  );
export const routeNotStartsWithPath = (...urls: string[]) =>
  pipe(
    map((r: RouterNavigatedAction) => r.payload.routerState),
    filter(
      (routerState: SerializedRouterStateSnapshot) =>
        !urls.some(url => routerState.url.startsWith(url)),
    ),
  );

export const routeMatchesRegExp = (...urlExps: RegExp[]) =>
  pipe(
    map((r: RouterNavigatedAction) => r.payload.routerState),
    filter((routerState: SerializedRouterStateSnapshot) =>
      urlExps.some(urlExp => urlExp.test(routerState.url)),
    ),
  );
export const routeNotMatchesRegExp = (...urlExps: RegExp[]) =>
  pipe(
    map((r: RouterNavigatedAction) => r.payload.routerState),
    filter(
      (routerState: SerializedRouterStateSnapshot) =>
        !urlExps.some(urlExp => urlExp.test(routerState.url)),
    ),
  );

export const routeMatches = (...urls: (string | RegExp)[]) =>
  pipe(
    map((r: RouterNavigatedAction) => r.payload.routerState),
    filter((routerState: SerializedRouterStateSnapshot) =>
      urls.some(url =>
        typeof url === 'string'
          ? routerState.url.startsWith(url)
          : url.test(routerState.url),
      ),
    ),
  );
export const routeNotMatches = (...urls: (string | RegExp)[]) =>
  pipe(
    map((r: RouterNavigatedAction) => r.payload.routerState),
    filter(
      (routerState: SerializedRouterStateSnapshot) =>
        !urls.some(url =>
          typeof url === 'string'
            ? routerState.url.startsWith(url)
            : url.test(routerState.url),
        ),
    ),
  );
