import { createSelector } from '@ngrx/store';
import {
  EntityState,
  getEntities,
  getIds,
  renderEntitiesAsArray,
} from '~ci-portal/utils/entity-utils';
import { Strategy } from '../../models';
import { featureState, FeatureState } from '../feature.state';

export const getStrategyState = (state: FeatureState): EntityState<Strategy> =>
  state.strategies;

export const strategyState = createSelector(featureState, getStrategyState);

export const strategyEntities = createSelector(strategyState, getEntities);

export const strategyIds = createSelector(strategyState, getIds);

export const allStrategies = createSelector(
  strategyEntities,
  strategyIds,
  renderEntitiesAsArray,
);

export const sortedStrategies = createSelector(
  allStrategies,
  (strategies: Strategy[]) => strategies.sort((a, b) => a.order - b.order),
);
