import { createAction, props } from '@ngrx/store';
import { CIProject, ProjectDetail } from '../../models';

export const projectDetailSaved = createAction(
  '[DOMAIN: CI Projects] (Project Detail) Saved',
  props<{ detail: Partial<ProjectDetail>, project: CIProject }>(),
);

export const projectDetailSavedSuccessfully = createAction(
  '[DOMAIN: CI Projects] (Project Detail) Saved Successfully',
);
