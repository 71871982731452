import { createSelector } from '@ngrx/store';
import { AuthenticatedUser, authenticatedUser } from '~ci-portal/core/auth';
import {
  EntityState,
  getEntities,
  getIds,
  getPendingInitialLoadFlag,
  renderEntitiesAsArray,
} from '~ci-portal/utils/entity-utils';
import { ProjectTeamMember } from '../../models';
import { featureState, FeatureState } from '../feature.state';

export const getProjectTeamMembersState = (
  state: FeatureState,
): EntityState<ProjectTeamMember> => state.projectTeamMembers;

export const projectTeamMembersState = createSelector(
  featureState,
  getProjectTeamMembersState,
);

export const projectTeamMemberEntities = createSelector(
  projectTeamMembersState,
  getEntities,
);

export const projectTeamMemberIds = createSelector(
  projectTeamMembersState,
  getIds,
);

export const allProjectTeamMembers = createSelector(
  projectTeamMemberEntities,
  projectTeamMemberIds,
  renderEntitiesAsArray,
);

export const getMyProjectTeamMembers = (
  members: ProjectTeamMember[],
  user?: AuthenticatedUser,
): string[] | undefined =>
  user
    ? members
        .filter(member => member.email === user.email)
        .map(member => member.id)
    : undefined;

export const myProjectTeamMemberIds = createSelector(
  allProjectTeamMembers,
  authenticatedUser,
  getMyProjectTeamMembers,
);

export const getMyProjectTeamProjects = (
  members: ProjectTeamMember[],
  user?: AuthenticatedUser,
): string[] | undefined =>
  user
    ? members
      .filter(member => member.email === user.email)
      .map(member => member.projectId)
    : undefined;

export const myProjectTeamProjectIds = createSelector(
  allProjectTeamMembers,
  authenticatedUser,
  getMyProjectTeamProjects,
);

export const projectTeamMemberPending = createSelector(
  projectTeamMembersState,
  getPendingInitialLoadFlag,
);

export const authenticatedUserProjects = createSelector(
  allProjectTeamMembers,
  authenticatedUser,
  (members: ProjectTeamMember[], user?: AuthenticatedUser): string[] =>
    user
      ? members
          .filter(member => member.email === user.email)
          .map(member => member.projectId)
      : [],
);
