import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { EntityState } from '~ci-portal/utils/entity-utils';
import { User } from './models/user.model';
import { userReducer } from './store/user.state';

export const FEATURE_NAME = 'users';
export const featureState = createFeatureSelector<FeatureState>(FEATURE_NAME);

export interface FeatureState {
  users: EntityState<User>;
}

export const reducer: ActionReducerMap<FeatureState> = {
  users: userReducer,
};
