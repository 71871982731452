import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { appInitializing } from '~ci-portal/core/app';
import { isAuthenticated } from '~ci-portal/core/auth';

@Component({
  selector: 'cip-monorepo-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  isAuthenticated$ = this.store.select(isAuthenticated);
  navigationEnd$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
  );
  showHeader$ = combineLatest([
    this.isAuthenticated$,
    this.navigationEnd$,
  ]).pipe(
    map(
      // The any below is required because the proper type, Event_2, is not exported from angular!!
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ([isAuthenticated]: [boolean, any]) =>
        isAuthenticated && !this.router.url?.includes('/report'),
    ),
  );
  showOutlet$ = combineLatest([
    this.isAuthenticated$,
    this.navigationEnd$,
  ]).pipe(
    map(
      // The any below is required because the proper type, Event_2, is not exported from angular!!
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ([isAuthenticated, event]: [boolean, any]) =>
        isAuthenticated || event.url === '/login',
    ),
  );

  constructor(private readonly store: Store, private readonly router: Router) {
    store.dispatch(appInitializing());
  }
}
