import { Action, createReducer, on } from '@ngrx/store';
import { AuthenticatedUser } from '~ci-portal/core/auth';
import { EntityState, mergeEntities, removeEntities } from '~ci-portal/utils/entity-utils';
import { User } from '../models/user.model';
import { usersAdded, usersModified, usersRemoved } from './user.actions';

export const initialUserState: EntityState<AuthenticatedUser> = {
  entities: {},
  ids: [],
  pendingInitialLoad: true,
}

export const mergeUsers = (
  state: EntityState<User>,
  { users }: { users: User[] },
) => mergeEntities(state, users, 'uid');

export const removeUsers = (
  state: EntityState<User>,
  { users }: { users: User[] },
) => removeEntities(state, users, 'uid');

export const reduce = createReducer(
  initialUserState,
  on(usersAdded, usersModified, mergeUsers),
  on(usersRemoved, removeUsers)
);

export function userReducer(
  state = initialUserState,
  action: Action
): EntityState<User> {
  return reduce(state, action);
}
