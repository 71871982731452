import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, mergeEntities, removeEntities } from '~ci-portal/utils/entity-utils';
import { OrgUnitArea } from '../../models';
import {
  orgUnitAreasAdded,
  orgUnitAreasModified,
  orgUnitAreasRemoved,
} from './org-unit-area.actions';

export const initialOrgUnitAreaState: EntityState<OrgUnitArea> = {
  entities: {},
  ids: [],
  pendingInitialLoad: true,
};

export const mergeOrgUnitAreas = (
  state: EntityState<OrgUnitArea>,
  { areas }: { areas: OrgUnitArea[] },
) => mergeEntities(state, areas, 'id');

export const removeOrgUnitAreas = (
  state: EntityState<OrgUnitArea>,
  { areas }: { areas: OrgUnitArea[] },
) => removeEntities(state, areas, 'id');

export const reduce = createReducer(
  initialOrgUnitAreaState,
  on(orgUnitAreasAdded, orgUnitAreasModified, mergeOrgUnitAreas),
  on(orgUnitAreasRemoved, removeOrgUnitAreas),
);

export function orgUnitAreaReducer(
  state = initialOrgUnitAreaState,
  action: Action,
): EntityState<OrgUnitArea> {
  return reduce(state, action);
}
