import { createAction, props } from '@ngrx/store';
import { CIProject } from '../../models';

export const ciProjectsAdded = createAction(
  '[DOMAIN: CI Projects] (CI Projects) Added',
  props<{ projects: CIProject[] }>(),
);

export const ciProjectsModified = createAction(
  '[DOMAIN: CI Projects] (CI Projects) Modified',
  props<{ projects: CIProject[] }>(),
);

export const ciProjectsRemoved = createAction(
  '[DOMAIN: CI Projects] (CI Projects) Removed',
  props<{ projects: CIProject[] }>(),
);

export const ciProjectSavingInitiated = createAction(
  '[DOMAIN: CI Projects] (CI Project) Saving Initiated',
  props<{ project: CIProject; skipUser?: boolean }>(),
);
export const ciProjectSaving = createAction(
  '[DOMAIN: CI Projects] (CI Project) Saving',
  props<{ project: CIProject; skipUser?: boolean }>(),
);

export const myProjectsChanged = createAction(
  '[DOMAIN: CI Projects] (My Projects) Changed',
  props<{ projects: CIProject[] }>(),
);

export const myProjectsWatched = createAction(
  '[DOMAIN: CI Projects] (My Projects) Watched',
);

export const myProjectsUnwatched = createAction(
  '[DOMAIN: CI Projects] (My Projects) Unwatched',
);
