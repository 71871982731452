import { createSelector } from '@ngrx/store';
import {
  EntityState,
  getEntities,
  getIds,
  getPendingInitialLoadFlag,
  renderEntitiesAsArray,
} from '~ci-portal/utils/entity-utils';
import { Milestone } from '../../models/milestone.model';
import { featureState, FeatureState } from '../feature.state';
import { MilestonesState } from './milestones.state';

export const getMilestonesState = (
  state: FeatureState,
): EntityState<Milestone> => state.milestones;

export const milestonesState = createSelector(featureState, getMilestonesState);

export const milestonesEntities = createSelector(milestonesState, getEntities);

export const milestonesIds = createSelector(milestonesState, getIds);

export const allMilestones = createSelector(
  milestonesEntities,
  milestonesIds,
  renderEntitiesAsArray,
);

export const milestonesPending = createSelector(
  milestonesState,
  getPendingInitialLoadFlag,
);

export const currentProjectMilestones = createSelector(
  milestonesState,
  (state: MilestonesState) =>
    state.currentProjectMilestones,
);

