import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatMenu } from '@angular/material/menu';

@Component({
  selector: 'cip-site-menu',
  templateUrl: './site-menu.component.html',
  styleUrls: ['./site-menu.component.scss'],
})
export class SiteMenuComponent {
  @ViewChild('menu') menu?: MatMenu;
  @Output() logOut = new EventEmitter();
  opened = false;
}
