import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap } from 'rxjs/operators';
import { setUpEntityChangeWatcherByRoutes } from '~ci-portal/utils/entity-utils';
import { prioritiesAdded, prioritiesModified, prioritiesRemoved, priorityDeleted } from './priority.actions';
import { PriorityService } from './priority.service';

@Injectable({ providedIn: 'root' })
export class PriorityDataEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly priorities: PriorityService,
  ) {
  }

  watchEntityChanges$ = createEffect(() =>
    this.actions$.pipe(
      setUpEntityChangeWatcherByRoutes(
        this.actions$,
        this.priorities,
        priorities => prioritiesAdded({ priorities }),
        priorities => prioritiesModified({ priorities }),
        priorities => prioritiesRemoved({ priorities }),
        '/ci-projects/edit',
        '/ci-projects/report',
        '/smart/team',
        '/smart/area',
        '/reports/smart-action-summary',
        '/admin/manage-goals',
        '/admin'
      ),
    ),
  );

  deletePriority$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(priorityDeleted),
        mergeMap(({ priority }) => this.priorities.delete(priority)),
      ),
    { dispatch: false },
  );
}
