import { createAction, props } from '@ngrx/store';
import { User } from '~ci-portal/domains/user';

export const userSaved = createAction(
  '[DOMAIN: Users] (User) Saved',
  props<{ user: User }>(),
);
export const userDeleted = createAction(
  '[DOMAIN: Users] (User) Deleted',
  props<{ user: User }>(),
);
