// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { CiEnvironment } from '~ci-portal/core/environment';

export const environment: CiEnvironment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAYY1w9tCMFsrC_bsafvkKvkKsKlNHBMTE",
    authDomain: "ciportaldev-fa23e.firebaseapp.com",
    projectId: "ciportaldev-fa23e",
    storageBucket: "ciportaldev-fa23e.appspot.com",
    messagingSenderId: "774381324995",
    appId: "1:774381324995:web:d99c5d1b1f158d7e877076",
    measurementId: "G-E182LVP3SG"
  },
  azure: {
    tenant: 'f6c4c21a-b064-4611-b230-8bad56f6342b'
  }
}
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
