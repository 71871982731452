import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';
import { appInitializing } from '~ci-portal/core/app';
import { LoginCanceledError } from '../models/authentication-error';
import { AuthService } from '../services/auth.service';
import {
  authenticationFailed,
  loggedInUserNotFound,
  loggedInUserVerificationFailed,
  loggedInUserVerified,
  userAuthenticated,
  userLoggedOut,
  userLoggingIn,
  userLoggingOut,
  userLogInCanceled,
} from './auth.actions';

const ERROR_TO_ACTION: { [type: string]: Action } = {
  [LoginCanceledError.name]: userLogInCanceled(),
};

@Injectable()
export class AuthEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly auth: AuthService,
  ) {}

  verifyLoggedInUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appInitializing),
      switchMap(() =>
        this.auth.user$.pipe(
          map(user =>
            user
              ? loggedInUserVerified({ uid: user.uid })
              : loggedInUserNotFound(),
          ),
          catchError(() => of(loggedInUserVerificationFailed())),
        ),
      ),
    ),
  );

  popupFirebaseAuth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userLoggingIn),
      exhaustMap(() =>
        this.auth.authenticate().pipe(
          map(user => userAuthenticated({ user })),
          catchError((error: Error) =>
            of(
              ERROR_TO_ACTION[error.constructor.name] ??
                authenticationFailed({ error }),
            ),
          ),
        ),
      ),
    ),
  );

  signUserOutOfFirebase$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userLoggingOut),
      exhaustMap(() => this.auth.signOut().pipe(map(() => userLoggedOut()))),
    ),
  );
}
