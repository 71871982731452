import { AngularFirestore } from '@angular/fire/compat/firestore';
import { from, Observable } from 'rxjs';
import { mapDocumentChangeActionToEntity } from './transformers';

export abstract class EntityService<T> {
  readonly added$: Observable<T[]>;
  readonly modified$: Observable<T[]>;
  readonly removed$: Observable<T[]>;

  constructor(
    private collectionName: string,
    protected keyProp: keyof T,
    protected readonly firestore: AngularFirestore,
    trackFirebaseId: boolean | string = true,
  ) {
    const col = firestore.collection<T>(collectionName);
    this.added$ = col
      .stateChanges(['added'])
      .pipe(mapDocumentChangeActionToEntity(trackFirebaseId));
    this.modified$ = col
      .stateChanges(['modified'])
      .pipe(mapDocumentChangeActionToEntity(trackFirebaseId));
    this.removed$ = col
      .stateChanges(['removed'])
      .pipe(mapDocumentChangeActionToEntity(trackFirebaseId));
  }

  protected col() {
    return this.firestore.collection<T>(this.collectionName);
  }

  loadAll(): Observable<T[]> {
    return from(this.col().valueChanges());
  }

  save(
    entity: T,
    { merge }: { merge: boolean } = { merge: false },
  ): Observable<void> {
    return from(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.col().doc((entity[this.keyProp] as any).toString()).set(entity, { merge }),
    );
  }

  delete(entity: T): Observable<void> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return from(this.col().doc((entity[this.keyProp] as any).toString()).delete());
  }
}
