<div *ngIf="user" class="d-flex flex-column justify-content-center align-items-center">
  <img class="user-profile-image" *ngIf="user.photoURL" [src]="user.photoURL" alt="" (click)="logOut.emit()"/>
  <button
    mat-fab color="primary" class="shadow-none" *ngIf="!user.photoURL"
    [matMenuTriggerFor]="menu"
    (menuClosed)="opened = false"
    (menuOpened)="opened = true">
    <mat-icon>person</mat-icon>
  </button>

  <h4 class="mt-2 mb-0">{{user.displayName}}</h4>
</div>

<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="logOut.emit()">
    <mat-icon>logout</mat-icon>
    <span>Log Out</span>
  </button>
  <button mat-menu-item *ngIf="user | hasRole:'admin'" (click)="administer.emit()">
    <mat-icon>admin_panel_settings</mat-icon>
    <span>Admin</span>
  </button>
</mat-menu>
