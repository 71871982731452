import { createAction, props } from '@ngrx/store';
import { CIProject } from '../../models';

export const projectHLCSaved = createAction(
  '[DOMAIN: CI Projects] (Project HLC) Saved',
  props<{ hlc: string; project: CIProject }>(),
);

export const projectHLCDeleted = createAction(
  '[DOMAIN: CI Projects] (Project HLC) Deleted',
  props<{ hlc: string; project: CIProject }>(),
);
