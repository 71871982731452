export interface SiteModule {
  name: string;
  url?: string;
  baseUrl?: string;
  roles?: string[];
  areas?: ModuleArea[];
  isDisabled?: boolean;
  isHidden?: boolean;
}

export interface ModuleArea {
  name: string;
  url: string;
  fallbackUrl?: string;
}

export const SITE_MODULES: SiteModule[] = [
  { name: 'Dashboard', url: '/' },
  {
    name: 'SMART Actions',
    baseUrl: '/smart',
    areas: [
      { name: 'Team Selection', url: '/smart/teams' },
      {
        name: 'SMART Actions Entry',
        url: '/smart/team/:orgUnitId',
        fallbackUrl: '/smart/teams',
      },
    ],
  },
  {
    name: 'CI Projects',
    baseUrl: '/ci-projects',
    areas: [
      { name: 'CI Projects', url: '/ci-projects/list' },
      { name: 'Create CI Project', url: '/ci-projects/create' },
      {
        name: 'CI Project Details',
        url: '/ci-projects/edit/:projectId',
        fallbackUrl: '/ci-projects/list',
      },
    ],
  },
  {
    name: 'Admin',
    baseUrl: '/admin',
    roles: ['admin'],
    areas: [
      {
        name: 'Dashboard', url: '/admin/dashboard',
      },
      {
        name: 'Manage Users', url: '/admin/manage-users'
      },
      {
        name: 'Manage Org Units', url: '/admin/manage-org-units'
      },
      {
        name: 'Manage Goals', url: '/admin/manage-goals'
      },
      {
        name: 'Manage Smart Actions', url: '/admin/manage-smart-actions'
      },
      {
        name: 'Manage HLCs', url: '/admin/manage-hlc'
      },
    ]
  },
  { name: 'Proposed Budget', url: '/budget', isDisabled: true, isHidden: true },
];
