import { Component, OnDestroy } from '@angular/core';
import { Signal } from './signal';

@Component({ template: '' })
export abstract class SmartComponent implements OnDestroy {
  protected destroyed$$ = new Signal();

  ngOnDestroy(): void {
    this.destroyed$$.finalBroadcast();
  }
}
