import {
  AbstractControl,
  AbstractControlOptions,
  AsyncValidatorFn,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
} from '@angular/forms';
import { format } from 'date-fns';
import { Subject } from 'rxjs';

interface FormConfig {
  controls: {
    [key: string]: unknown;
  };
  validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null;
  asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null;
}

export const areAllNull = <T>(form: T, ...keys: (keyof T)[]) =>
  keys.some(key => (form[key] as unknown as AbstractControl).value === null);

export abstract class TypedFormGroup<TModel> extends FormGroup {
  protected model?: Partial<TModel>; // Internally track the original or latest model that populated/patched the form

  get originalModel(): Partial<TModel> {
    return { ...(this.model ?? {}) };
  }

  get updatedModel(): Partial<TModel> {
    return {
      ...this.model, // Copy original model FIRST!! This copies in any properties not part of the form!! (i.e. ids, server-generated fields, etc.)
      ...this.value, // Copy over the original model any properties managed by the form, thus overwriting those values with the latest from the form
    };
  }

  abstract get isEmpty(): boolean;

  protected constructor(
    { controls, validatorOrOpts, asyncValidator }: FormConfig,
    model?: Partial<TModel>,
    builder = new FormBuilder(),
  ) {
    const group = builder.group(controls);
    super(group.controls, validatorOrOpts, asyncValidator);
    this.model = model;
  }

  protected getControl(name: keyof TModel | string): AbstractControl | null {
    return this.get(name as string);
  }

  protected getFormControl(name: keyof TModel | string): FormControl {
    return this.getControl(name) as FormControl;
  }

  protected getFormGroup(name: keyof TModel | string): FormGroup {
    return this.getControl(name) as FormGroup;
  }

  protected getFormArray(name: keyof TModel | string): FormArray {
    return this.getControl(name) as FormArray;
  }

  protected getTypedControl<TControl extends FormControl>(
    name: string,
  ): TControl {
    return this.getFormControl(name) as TControl;
  }

  protected getTypedGroup<TGroup extends FormGroup>(name: string): TGroup {
    return this.getFormGroup(name) as TGroup;
  }

  protected getTypedArray<TArray extends FormArray>(name: string): TArray {
    return this.getFormArray(name) as TArray;
  }

  protected transformModel(model: Partial<TModel>): any {
    return model;
  }

  patchModel(model: Partial<TModel>): void {
    this.patchValue(model);
    this.updateValueAndValidity();
  }

  patchValue(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: { [p: string]: any },
    options?: { onlySelf?: boolean; emitEvent?: boolean },
  ): void {
    this.model = { ...this.updatedModel, ...(value as Partial<TModel>) };
    const txvalue = this.transformModel(value as Partial<TModel>);
    super.patchValue(txvalue, options);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  enableValidators(): void {
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  disableValidators(): void {
  }
}

export class TypedFormArray<TModel,
  TItem extends TypedFormGroup<TModel>,
  > extends FormArray {
  readonly items = this.controls as TItem[];
  readonly itemsChanged$$ = new Subject<TItem[]>();

  constructor(controls: AbstractControl[]) {
    super(controls);
    this.itemsChanged$$.next(this.items);
  }

  patchValue(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any[],
    options?: { onlySelf?: boolean; emitEvent?: boolean },
  ) {
    super.patchValue(value, options);
    value // This pushes any values beyond however many are already in the FormArray
      .slice(this.controls.length)
      .forEach(value => this.push(this.makeNewGroup(value)));
    this.itemsChanged$$.next(this.items);
  }

  protected makeNewGroup(model: TModel, ...args: unknown[]): TItem {
    throw new Error('Not Implemented');
  }

  enableValidators(indices?: number[] | ((index: number) => boolean)): void {
    !indices
      ? this.items.forEach(item => item.enableValidators())
      : Array.isArray(indices)
        ? this.items.forEach((item, index) =>
          indices.includes(index) ? item.enableValidators() : void 0,
        )
        : this.items.forEach((item, index) =>
          indices(index) ? item.enableValidators() : void 0,
        );
  }

  disableValidators(indices?: number[] | ((index: number) => boolean)): void {
    !indices
      ? this.items.forEach(item => item.disableValidators())
      : Array.isArray(indices)
        ? this.items.forEach((item, index) =>
          indices.includes(index) ? item.disableValidators() : void 0,
        )
        : this.items.forEach((item, index) =>
          indices(index) ? item.disableValidators() : void 0,
        );
  }
}

export class PrimitiveControl<T extends string | number | boolean | bigint | Date,
  > extends FormControl {
  constructor(
    formState?: T,
    validatorOrOpts?:
      | ValidatorFn
      | ValidatorFn[]
      | AbstractControlOptions
      | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
  ) {
    super(formState, validatorOrOpts, asyncValidator);
  }

  setValue(
    value: T,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
      emitModelToViewChange?: boolean;
      emitViewToModelChange?: boolean;
    },
  ) {
    return super.setValue(value, options);
  }
}
