import { createAction, props } from '@ngrx/store';
import { UnitTeamMember } from '../../models';

export const unitTeamsAdded = createAction(
  '[DOMAIN: UNIT TEAMS] (Unit Teams) Added',
  props<{ teamMembers: UnitTeamMember[] }>(),
);

export const unitTeamsModified = createAction(
  '[DOMAIN: UNIT TEAMS] (Unit Teams) Modified',
  props<{ teamMembers: UnitTeamMember[] }>(),
);

export const unitTeamsRemoved = createAction(
  '[DOMAIN: UNIT TEAMS] (Unit Teams) Removed',
  props<{ teamMembers: UnitTeamMember[] }>(),
);

export const unitTeamMemberSaved = createAction(
  '[DOMAIN: UNIT TEAMS] (Unit Team) Saved',
  props<{ teamMember: UnitTeamMember }>(),
);

export const unitTeamMemberDeleted = createAction(
  '[DOMAIN: UNIT TEAMS] (Unit Team) Deleted',
  props<{ teamMember: UnitTeamMember }>(),
);
