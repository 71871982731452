import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FEATURE_NAME, reducer } from './store/feature.state';
import { OrgUnitAreaDataEffects } from './store/org-unit-area/org-unit-area-data.effects';
import { OrgUnitDivDataEffects } from './store/org-unit-div/org-unit-div-data.effects';
import { OrgUnitDataEffects } from './store/org-unit/org-unit-data.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(FEATURE_NAME, reducer),
    EffectsModule.forFeature([
      OrgUnitDivDataEffects,
      OrgUnitAreaDataEffects,
      OrgUnitDataEffects,
    ]),
  ],
})
export class DomainsOrgUnitModule {}
