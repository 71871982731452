import { createAction, props } from '@ngrx/store';
import { OrgUnit } from '../../models';

export const orgUnitsLoaded = createAction(
  '[STORE: Org Units] (Units) Loaded',
  props<{ units: OrgUnit[] }>(),
);

export const orgUnitsAdded = createAction(
  '[STORE: Org Units] (Units) Added',
  props<{ units: OrgUnit[] }>(),
);

export const orgUnitsModified = createAction(
  '[STORE: Org Units] (Units) Modified',
  props<{ units: OrgUnit[] }>(),
);

export const orgUnitsRemoved = createAction(
  '[STORE: Org Units] (Units) Removed',
  props<{ units: OrgUnit[] }>(),
);

export const orgUnitSaved = createAction(
  '[DOMAIN: Org Units] (Unit) Saved',
  props<{ orgUnit: OrgUnit }>(),
);

export const orgUnitDeleted = createAction(
  '[DOMAIN: Org Units] (Unit) Deleted',
  props<{ orgUnit: OrgUnit }>(),
);
