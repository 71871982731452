import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LoginButtonComponent } from './components/login-button/login-button.component';
import { AuthEffects } from './store/auth.effects';
import { AUTH_FEATURE, authReducer } from './store/auth.state';
import { UserEffects } from './store/user.effects';
import { HasRolePipe } from './pipes/has-role.pipe';

@NgModule({
  declarations: [LoginButtonComponent, HasRolePipe],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    StoreModule.forFeature(AUTH_FEATURE, authReducer),
    EffectsModule.forFeature([AuthEffects, UserEffects]),
  ],
  exports: [LoginButtonComponent, HasRolePipe],
})
export class AuthModule {}
