import { HLC } from '../../models';
import { createAction, props } from '@ngrx/store';

export const hlcsLoaded = createAction(
  '[DOMAIN: CI Projects] (HLCs) Loaded',
  props<{ hlcs: HLC[] }>(),
);

export const hlcsAdded = createAction(
  '[DOMAIN: CI Projects] (HLCs) Added',
  props<{ hlcs: HLC[] }>(),
);

export const hlcsModified = createAction(
  '[DOMAIN: CI Projects] (HLCs) Modified',
  props<{ hlcs: HLC[] }>(),
);

export const hlcsRemoved = createAction(
  '[DOMAIN: CI Projects] (HLCs) Removed',
  props<{ hlcs: HLC[] }>(),
);

export const hlcSaved = createAction(
  '[DOMAIN: CI Projects] (HLC) Saved',
  props<{ hlc: Partial<HLC> }>(),
);

export const hlcDeleted = createAction(
  '[DOMAIN: CI Projects] (HLC) Deleted',
  props<{ hlc: HLC }>(),
);
