import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { from, Observable } from 'rxjs';
import { AuthenticatedUser } from '../models/authenticated-user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private collection = this.firestore.collection('users');

  constructor(
    private readonly firestore: AngularFirestore
  ) { }

  getRef(userId: string): AngularFirestoreDocument<AuthenticatedUser> {
    const ref = this.collection.doc<AuthenticatedUser>(userId);
    return ref;
  }

  update(user: Partial<AuthenticatedUser>, ref: AngularFirestoreDocument): Observable<void> {
    return from(ref.update(user));
  }

  create(user: AuthenticatedUser, ref: AngularFirestoreDocument): Observable<void> {
    return from(ref.set(user, { merge: true }));
  }
}
