import { Inject, Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { format } from 'date-fns';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { from, Observable, of, ReplaySubject, throwError } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { CiEnvironment, ENVIRONMENT } from '~ci-portal/core/environment';
import { AuthenticatedUser } from '../models/authenticated-user.model';
import { AuthenticationError, LoginCanceledError } from '../models/authentication-error';
import { MSProfile } from '../models/MSProfile';
import User = firebase.User;
import UserInfo = firebase.UserInfo;

export const buildGoogleStandardUser = (user: UserInfo): AuthenticatedUser => ({
  uid: user.uid,
  email: user.email!,
  displayName: user.displayName,
  photoURL: null,
});

export const buildMSExtendedUser = (
  profile: MSProfile,
): Partial<AuthenticatedUser> => ({
  businessPhones: profile.businessPhones,
  mobilePhone: profile.mobilePhone,
  officeLocation: profile.officeLocation,
  surname: profile.surname,
  givenName: profile.givenName,
  jobTitle: profile.jobTitle,
  id: profile.id,
  userPrincipalName: profile.userPrincipalName,
});

export const tryBuildMSExtendedUser = (profile: MSProfile | null | undefined) =>
  profile && buildMSExtendedUser(profile);

const ERROR_CODE_TO_ERROR_TYPE: {
  [code: string]: typeof LoginCanceledError | typeof Error;
} = {
  'auth/popup-closed-by-user': LoginCanceledError,
  'auth/popup-blocked': LoginCanceledError,
};

@Injectable({ providedIn: 'root' })
export class AuthService {
  private readonly user$$ = new ReplaySubject<User>(1);
  readonly user$ = this.user$$.asObservable();

  constructor(
    private readonly fireAuth: AngularFireAuth,
    @Inject(ENVIRONMENT) private readonly env: CiEnvironment,
  ) {
    this.fireAuth.user.subscribe(user => this.user$$.next(user ?? undefined));
  }

  authenticate(): Observable<AuthenticatedUser> {
    return of(new firebase.auth.OAuthProvider('microsoft.com')).pipe(
      tap(provider =>
        provider.setCustomParameters({ tenant: this.env.azure.tenant }),
      ),
      switchMap(provider =>
        from(this.fireAuth.setPersistence('local')).pipe(map(() => provider)),
      ),
      exhaustMap(provider =>
        from(this.fireAuth.signInWithPopup(provider)).pipe(
          tap(credential => {
            if (!credential.user) {
              throw new AuthenticationError(
                'Authentication failed to provide a user!',
              );
            }
          }),
          map(credential => ({
            user: credential.user as UserInfo,
            profile: credential.additionalUserInfo?.profile,
          })),
          map(({ user, profile }) => ({
            ...buildGoogleStandardUser(user),
            ...tryBuildMSExtendedUser(profile),
            roles: ['editor', 'user'],
            createDate: format(Date.now(), 'MM/dd/yyyy hh:mm'),
            lastLoginDate: format(Date.now(), 'MM/dd/yyyy hh:mm'),
          })),
          catchError(error =>
            !!error?.code && typeof error.code === 'string'
              ? throwError(
                new ERROR_CODE_TO_ERROR_TYPE[error.code](error.message),
              )
              : throwError(new AuthenticationError(error.message)),
          ),
        ),
      ),
    );
  }

  signOut(): Observable<void> {
    return from(this.fireAuth.signOut());
  }
}
