import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FEATURE_NAME, reducer } from './store/feature.state';
import { SmartActionDataEffects } from './store/smart-actions/smart-action-data.effects';
import { SmartFavoritesEffects } from './store/smart-favorites/smart-favorites.effects';
import { UnitTeamDataEffects } from './store/unit-teams/unit-team-data.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(FEATURE_NAME, reducer),
    EffectsModule.forFeature([
      SmartActionDataEffects,
      SmartFavoritesEffects,
      UnitTeamDataEffects,
    ]),
  ],
})
export class DomainsSmartActionsModule {}
