export class AuthenticationError extends Error implements Error {
  constructor(message: string) {
    super(message);
  }
}

export class LoginCanceledError extends AuthenticationError {
  constructor(public readonly innerMessage: string) {
    super('Login canceled by user');
  }
}
