import { compose } from '~ci-portal/utils/common-utils';
import { HLCCriterion, HLC } from '../../models';

export const getParentId = (id?: string) => id?.split('-')?.[1];

export const addCompetencyToCriterion = (current: HLCCriterion, hlc: HLC) => ({
  ...current,
  competencies: [...current.competencies, hlc],
});

export const addCompetencyToCurrentAndContinue =
  (hlc: HLC) =>
  ({ all, current }: Accumulator) => ({
    all,
    current: addCompetencyToCriterion(current!, hlc),
  });

export const trackNewCriterion =
  (hlc: HLC) =>
  ({ all }: Accumulator): Accumulator => ({
    all,
    current: {
      ...hlc,
      competencies: [],
    },
  });

export const captureCurrent = ({ all, current }: Accumulator): Accumulator =>
  current
    ? {
        all: [...all, current],
        current: undefined,
      }
    : { all, current };

export const captureIfLast = (index: number, length: number,) => ({ all, current }: Accumulator): Accumulator =>
  index === length - 1 ? captureCurrent({ all, current }) : { all, current };

export const captureCurrentAndAddNewCriterion = (hlc: HLC) =>
  compose(captureCurrent, trackNewCriterion(hlc))

interface Accumulator {
  all: HLCCriterion[];
  current?: HLCCriterion;
}

export const buildHLCTree = (hlcs: HLC[]): HLCCriterion[] =>
  hlcs.reduce(
    ({ all, current }: Accumulator, hlc, index, { length }) =>
      getParentId(current?.id) === getParentId(hlc.id)
        ? compose(addCompetencyToCurrentAndContinue(hlc), captureIfLast(index, length))({ all, current })
        : compose(captureCurrentAndAddNewCriterion(hlc), captureIfLast(index, length))({ all, current }),
    {
      all: [],
      current: undefined,
    },
  ).all;

export const keepCriterion = (hlc: HLC) => hlc.id.endsWith('0');
