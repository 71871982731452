import { createSelector } from '@ngrx/store';
import {
  EntityDictionary,
  EntityIds,
  EntityState,
  getPendingInitialLoadFlag,
} from '~ci-portal/utils/entity-utils';
import { ProjectFavorite } from '../../models/project-favorite.model';
import { featureState, FeatureState } from '../feature.state';

export const getFavoritesState = (
  state: FeatureState,
): EntityState<ProjectFavorite> => state.projectFavorites;

export const getProjectFavoriteEntities = (
  state: EntityState<ProjectFavorite>,
): EntityDictionary<ProjectFavorite> => state.entities;

export const getProjectFavoriteIds = (
  state: EntityState<ProjectFavorite>,
): EntityIds => state.ids;

const renderEntitiesAsArray = (
  entities: EntityDictionary<ProjectFavorite>,
  ids: EntityIds,
): ProjectFavorite[] => ids.map(id => entities[id]) as ProjectFavorite[];

export const projectFavoritesState = createSelector(
  featureState,
  getFavoritesState,
);

export const projectFavoritesEntities = createSelector(
  projectFavoritesState,
  getProjectFavoriteEntities,
);

export const projectFavoritesIds = createSelector(
  projectFavoritesState,
  getProjectFavoriteIds,
);

export const allProjectFavorites = createSelector(
  projectFavoritesEntities,
  projectFavoritesIds,
  renderEntitiesAsArray,
);

export const projectFavoritesPending = createSelector(
  projectFavoritesState,
  getPendingInitialLoadFlag,
);
