import { Pipe, PipeTransform } from '@angular/core';
import { SiteModule } from '../site-modules';
import { whereModuleMatchesUrl } from '../utils/modules';

@Pipe({ name: 'isActiveModule' })
export class IsActiveModulePipe implements PipeTransform {
  transform(module: SiteModule, currentUrl?: string | null): boolean {
    return whereModuleMatchesUrl(currentUrl)(module);
  }
}
