export const whereKeyMatchesId =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <T>(prop: keyof T, id?: any) =>
    (item: T) =>
      item[prop] === id;

export const whereKeyFoundInSet =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <T>(prop: keyof T, set: any[]) =>
    (item: T) =>
      set.includes(item[prop]);
