import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap } from 'rxjs/operators';
import { loggedInUserVerified, userAuthenticated } from '~ci-portal/core/auth';
import { setUpEntityChangeWatcherByActions } from '~ci-portal/utils/entity-utils';
import { orgUnitDeleted, orgUnitsAdded, orgUnitSaved, orgUnitsModified, orgUnitsRemoved } from './org-unit.actions';
import { OrgUnitService } from './org-unit.service';

@Injectable({ providedIn: 'root' })
export class OrgUnitDataEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly orgUnits: OrgUnitService,
  ) {
  }

  watchEntityChanges$ = createEffect(() =>
    this.actions$.pipe(
      setUpEntityChangeWatcherByActions(
        this.actions$,
        this.orgUnits,
        units => orgUnitsAdded({ units }),
        units => orgUnitsModified({ units }),
        units => orgUnitsRemoved({ units }),
        userAuthenticated,
        loggedInUserVerified,
      ),
    ),
  );

  deleteOrgUnit$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(orgUnitDeleted),
        mergeMap(({ orgUnit }) => this.orgUnits.delete(orgUnit)),
      ), { dispatch: false },
  );

  saveOrgUnit$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(orgUnitSaved),
        mergeMap(({ orgUnit }) => this.orgUnits.save(orgUnit)),
      ), { dispatch: false },
  );
}
