import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { userLoggingIn, userLoggingOut } from '../../store/auth.actions';
import { isAuthenticated, isAuthenticating } from '../../store/auth.selectors';

@Component({
  selector: 'cip-login-button',
  templateUrl: './login-button.component.html',
  styleUrls: ['./login-button.component.scss'],
})
export class LoginButtonComponent {
  isAuthenticated$ = this.store.select(isAuthenticated);
  isAuthenticating$ = this.store.select(isAuthenticating);

  constructor(private readonly store: Store) {}

  login() {
    this.store.dispatch(userLoggingIn());
  }

  logout() {
    this.store.dispatch(userLoggingOut());
  }
}
