import { createAction, props } from '@ngrx/store';
import { Strategy } from '../../models';

export const strategiesLoaded = createAction(
  '[STORE: Planning] (Strategies) Loaded',
  props<{ strategies: Strategy[] }>(),
);

export const strategiesAdded = createAction(
  '[STORE: Planning] (Strategies) Added',
  props<{ strategies: Strategy[] }>(),
);

export const strategiesModified = createAction(
  '[STORE: Planning] (Strategies) Modified',
  props<{ strategies: Strategy[] }>(),
);

export const strategiesRemoved = createAction(
  '[STORE: Planning] (Strategies) Removed',
  props<{ strategies: Strategy[] }>(),
);

export const strategySaved = createAction(
  '[STORE: Planning] (Strategy) Saved',
  props<{ strategy: Strategy }>(),
);

export const strategyDeleted = createAction(
  '[STORE: Planning] (Strategy) Deleted',
  props<{ strategy: Strategy }>(),
);
