import { createAction, props } from '@ngrx/store';
import { Milestone } from '../../models/milestone.model';

export const milestonesLoaded = createAction(
  '[DOMAIN: CI Project] (Milestones) Loaded',
  props<{ milestones: Milestone[] }>(),
);

export const milestonesAdded = createAction(
  '[DOMAIN: CI Project] (Milestones) Added',
  props<{ milestones: Milestone[] }>(),
);

export const milestonesModified = createAction(
  '[DOMAIN: CI Project] (Milestones) Modified',
  props<{ milestones: Milestone[] }>(),
);

export const milestonesRemoved = createAction(
  '[DOMAIN: CI Project] (Milestones) Removed',
  props<{ milestones: Milestone[] }>(),
);

export const milestoneSaved = createAction(
  '[DOMAIN: CI Project] (Milestone) Saved',
  props<{ milestone: Milestone }>(),
);

export const milestoneDeleted = createAction(
  '[DOMAIN: CI Project] (Milestone) Deleted',
  props<{ milestone: Milestone }>(),
);

export const milestonesSaved = createAction(
  '[DOMAIN: CI Project] (Milestones) Saved',
  props<{ milestones: Milestone[] }>(),
);

export const projectMilestonesChanged = createAction(
  '[DOMAIN: CI Project] (Project Milestones) Changed',
  props<{ milestones: Milestone[] }>(),
);

export const myProjectMilestonesChanged = createAction(
  '[DOMAIN: CI Project] (My Project Milestones) Changed',
  props<{ milestones: Milestone[] }>(),
);

export const projectMilestonesWatched = createAction(
  '[DOMAIN: CI Project] (Project Milestones) Watched',
  props<{ projectId: string }>(),
);

export const projectMilestonesUnwatched = createAction(
  '[DOMAIN: CI Project] (Project Milestones) Unwatched',
);

export const myProjectMilestonesWatched = createAction(
  '[DOMAIN: CI Project] (My Project Milestones) Watched',
);

export const myProjectMilestonesUnwatched = createAction(
  '[DOMAIN: CI Project] (My Project Milestones) Unwatched',
);
