import { SiteModule } from '../site-modules';

export const findCurrentModule = (modules?: SiteModule[], currentUrl?: string | null): SiteModule | undefined =>
  modules?.find(whereModuleMatchesUrl(currentUrl));

export const whereModuleMatchesUrl = (url?: string | null) => (module: SiteModule): boolean =>
  module.url
    ? url === module.url
    : module.baseUrl
    ? !!url?.startsWith(module.baseUrl)
    : false;
