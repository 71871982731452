import { createAction, props } from '@ngrx/store';
import { OrgUnitArea } from '../../models';

export const orgUnitAreasAdded = createAction(
  '[STORE: Org Unit Areas] (Areas) Added',
  props<{ areas: OrgUnitArea[] }>(),
);

export const orgUnitAreasModified = createAction(
  '[STORE: Org Unit Areas] (Areas) Modified',
  props<{ areas: OrgUnitArea[] }>(),
);

export const orgUnitAreasRemoved = createAction(
  '[STORE: Org Unit Areas] (Areas) Removed',
  props<{ areas: OrgUnitArea[] }>(),
);

export const orgUnitAreaSaved = createAction(
  '[DOMAIN: Org Unit Areas] (Area) Saved',
  props<{ area: OrgUnitArea }>(),
);

export const orgUnitAreaDeleted = createAction(
  '[DOMAIN: Org Unit Areas] (Area) Deleted',
  props<{ area: OrgUnitArea }>(),
);
