export const findMax = (max: number, value: number): number =>
  max < value ? value : max;

export const sumTrue = (count: number, value: boolean): number =>
  count + (value ? 1 : 0);

export const sumFalse = (count: number, value: boolean): number =>
  count + (value ? 0 : 1);

export const excluding =
  <T>(others: T[]) =>
  (item: T) =>
    !others.includes(item);

export const including =
  <T>(others: T[]) =>
  (item: T) =>
    others.includes(item);

export const partition =
  <T>(size = 10) =>
  (partitions: Array<T>[] = [], item: T) =>
    (partitions.length === 0 ||
    partitions[partitions.length - 1].length % size === 0)
      ? [...partitions, [item]]
      : [
          ...partitions.slice(0, partitions.length - 1),
          [...partitions[partitions.length - 1], item],
        ];
