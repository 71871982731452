import { createAction, props } from '@ngrx/store';
import { ProjectTeamMember } from '../../models';

export const projectTeamMembersAdded = createAction(
  '[DOMAIN: CI Projects] (Project Team Members) Added',
  props<{ teamMembers: ProjectTeamMember[] }>(),
);

export const projectTeamMembersModified = createAction(
  '[DOMAIN: CI Projects] (Project Team Members) Modified',
  props<{ teamMembers: ProjectTeamMember[] }>(),
);

export const projectTeamMembersRemoved = createAction(
  '[DOMAIN: CI Projects] (Project Team Members) Removed',
  props<{ teamMembers: ProjectTeamMember[] }>(),
);

export const projectTeamMemberSaved = createAction(
  '[DOMAIN: CI Projects] (Project Team Member) Saved',
  props<{ teamMember: ProjectTeamMember }>(),
);

export const projectTeamMemberSaving = createAction(
  '[DOMAIN: CI Projects] (Project Team Member) Saving',
  props<{ teamMember: ProjectTeamMember }>(),
);

export const projectTeamMemberSavedSuccessfully = createAction(
  '[DOMAIN: CI Projects] (Project Team Member) Saved Successfully',
  props<{ teamMember: ProjectTeamMember }>(),
);

export const projectTeamMemberDeleted = createAction(
  '[DOMAIN: CI Projects] (Project Team Member) Deleted',
  props<{ teamMember: ProjectTeamMember }>(),
);

export const projectTeamMembersChanged = createAction(
  '[DOMAIN: CI Project] (Project Team Members) Changed',
  props<{ teamMembers: ProjectTeamMember[] }>()
)

export const myProjectTeamMembersWatched = createAction(
  '[DOMAIN: CI Projects] (My Project Team Members) Watched',
);

export const myProjectTeamMembersUnwatched = createAction(
  '[DOMAIN: CI Projects] (My Project Team Members) Unwatched',
);


