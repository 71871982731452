import { createSelector } from '@ngrx/store';
import { AuthenticatedUser, authenticatedUser } from '~ci-portal/core/auth';
import {
  EntityState,
  getEntities,
  getIds,
  renderEntitiesAsArray,
} from '~ci-portal/utils/entity-utils';
import { UnitTeamMember } from '../../models';
import { featureState, FeatureState } from '../feature.state';

export const getTeamMemberState = (
  state: FeatureState,
): EntityState<UnitTeamMember> => state.unitTeams;

export const unitTeamsState = createSelector(featureState, getTeamMemberState);

export const unitTeamMemberEntities = createSelector(
  unitTeamsState,
  getEntities,
);

export const unitTeamMemberIds = createSelector(unitTeamsState, getIds);

export const allUnitTeamMembers = createSelector(
  unitTeamMemberEntities,
  unitTeamMemberIds,
  renderEntitiesAsArray,
);

export const authenticatedUserTeams = createSelector(
  allUnitTeamMembers,
  authenticatedUser,
  (members: UnitTeamMember[], user?: AuthenticatedUser): string[] =>
    user
      ? members
          .filter(member => member.email === user.email)
          .map(member => member.spOrgUnitID)
      : [],
);
