import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { EntityService } from '~ci-portal/utils/entity-utils';
import { Goal } from '../../models';

@Injectable({ providedIn: 'root' })
export class GoalService extends EntityService<Goal> {
  constructor(firestore: AngularFirestore) {
    super('spGoal', 'id', firestore, 'key');
  }
}
