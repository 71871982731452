import { EntityDictionary, EntityIds, EntityState } from './index';

export const getEntities = <T>(
  state: EntityState<T>,
): EntityDictionary<T> => state.entities;

export const getIds = <T>(state: EntityState<T>): EntityIds => state.ids;

export const renderEntitiesAsArray = <T>(
  entities: EntityDictionary<T>,
  ids: EntityIds,
): T[] => ids.map(id => entities[id]) as T[];

export const getPendingInitialLoadFlag = <T>(
  state: EntityState<T>
): boolean => state.pendingInitialLoad;
