import { ProjectDetail } from './project-detail.model';
import { ProjectGoal } from './project-goal.model';
import { ProjectLink } from './project-link.model';

export enum CIProjectStage {
  focus = 'FOCUS',
  plan = 'PLAN',
  do = 'DO',
  check = 'CHECK',
  adjust = 'ADJUST',
  complete = 'COMPLETE',
  deferred = 'DEFERRED',
  cancelled = 'CANCELLED',
  empty = '',
}

export interface CIProject {
  archivalRequested?: boolean;
  archived: boolean;
  created: string;
  createdBy: string;
  department: string;
  draftMode: 'Yes' | 'No' | boolean;
  modified: string;
  modifiedBy: string;
  prettyId: number;
  projectId: string;
  stage: CIProjectStage;
  submitDate?: string;
  title: string;

  detail: ProjectDetail;
  goals: ProjectGoal[];
  hlc: string[];
  links: ProjectLink[];
}
