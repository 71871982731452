import { createSelector } from '@ngrx/store';
import { featureState, LayoutState } from './feature';
import { NavState } from './nav.state';

export const navState = createSelector(
  featureState,
  (state: LayoutState) => state.nav,
);

export const allCurrentParams = createSelector(
  navState,
  (state: NavState) => state.currentParams,
);
