import { createSelector } from '@ngrx/store';
import {
  EntityState,
  getEntities,
  getIds,
  renderEntitiesAsArray,
} from '~ci-portal/utils/entity-utils';
import { OrgUnitDiv } from '../../models';
import { featureState, FeatureState } from '../feature.state';

export const getOrgUnitDivState = (
  state: FeatureState,
): EntityState<OrgUnitDiv> => state.orgUnitDivs;

export const orgUnitDivState = createSelector(featureState, getOrgUnitDivState);

export const orgUnitDivEntities = createSelector(orgUnitDivState, getEntities);

export const orgUnitDivIds = createSelector(orgUnitDivState, getIds);

export const allOrgUnitDivs = createSelector(
  orgUnitDivEntities,
  orgUnitDivIds,
  renderEntitiesAsArray,
);

export const sortedOrgUnitDivs = createSelector(
  allOrgUnitDivs,
  (divisions: OrgUnitDiv[]) => divisions.sort((a, b) => a.order - b.order),
);
