import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap } from 'rxjs/operators';
import { CIProjectService } from './ci-project.service';
import { projectHLCDeleted, projectHLCSaved } from './project-hlc.actions';

@Injectable({ providedIn: 'root' })
export class ProjectHLCDataEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly projects: CIProjectService,
  ) {}

  updateHLC$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(projectHLCSaved),
        mergeMap(({ project, hlc }) => this.projects.addHLC(hlc, project)),
      ),
    { dispatch: false },
  );

  removeProjectHLCs$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(projectHLCDeleted),
        mergeMap(({ project, hlc }) => this.projects.deleteHLC(hlc, project)),
      ),
    { dispatch: false },
  );
}
