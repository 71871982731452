import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { EntityService } from '~ci-portal/utils/entity-utils';
import { Strategy } from '../../models';

@Injectable({ providedIn: 'root' })
export class StrategyService extends EntityService<Strategy> {
  constructor(firestore: AngularFirestore) {
    super('spStrategy', 'id', firestore, 'key');
  }
}
