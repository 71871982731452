import { Action, createReducer, on } from '@ngrx/store';
import { applyReductions } from '~ci-portal/utils/common-utils';
import {
  EntityState,
  mergeEntities,
  removeEntities,
} from '~ci-portal/utils/entity-utils';
import { CIProject } from '../../models';
import {
  ciProjectsAdded,
  ciProjectsModified,
  ciProjectsRemoved,
  myProjectsChanged,
} from './ci-project.actions';

export const initialCIProjectState: EntityState<CIProject> = {
  entities: {},
  ids: [],
  pendingInitialLoad: true,
};

export const mergeCIProjects = (
  state: EntityState<CIProject>,
  { projects }: { projects: CIProject[] },
) => mergeEntities(state, projects, 'projectId');

export const removeCIProjects = (
  state: EntityState<CIProject>,
  { projects }: { projects: CIProject[] },
) => removeEntities(state, projects, 'projectId');

export const clearCIProjects = (
  state: EntityState<CIProject>,
): EntityState<CIProject> => ({
  ...state,
  entities: {},
  ids: [],
});

export const reduce = createReducer(
  initialCIProjectState,
  on(ciProjectsAdded, ciProjectsModified, mergeCIProjects),
  on(ciProjectsRemoved, removeCIProjects),
  on(myProjectsChanged, applyReductions(clearCIProjects, mergeCIProjects)),
);

export function ciProjectReducer(
  state = initialCIProjectState,
  action: Action,
): EntityState<CIProject> {
  return reduce(state, action);
}
