import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { EntityState } from '~ci-portal/utils/entity-utils';
import { SmartAction, UnitTeamMember } from '../models';
import { SmartFavorite } from '../models/smart-favorite.model';
import { smartActionReducer } from './smart-actions/smart-action.state';
import { smartFavoriteReducer } from './smart-favorites/smart-favorites.state';
import { unitTeamsReducer } from './unit-teams/unit-team.state';

export const FEATURE_NAME = 'smartActions';
export const featureState = createFeatureSelector<FeatureState>(FEATURE_NAME);

export interface FeatureState {
  smartActions: EntityState<SmartAction>;
  unitTeams: EntityState<UnitTeamMember>;
  favorites: EntityState<SmartFavorite>;
}

export const reducer: ActionReducerMap<FeatureState> = {
  smartActions: smartActionReducer,
  unitTeams: unitTeamsReducer,
  favorites: smartFavoriteReducer,
};
