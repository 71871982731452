import { createAction, props } from '@ngrx/store';
import { CIProject, ProjectGoal } from '../../models';

export const projectGoalSaved = createAction(
  '[DOMAIN: CI Projects] (Project Goal) Saved',
  props<{ goal: ProjectGoal; project: CIProject }>(),
);

export const projectGoalSaving = createAction(
  '[DOMAIN: CI Projects] (Project Goal) Saving',
  props<{ goal: ProjectGoal; project: CIProject }>(),
);

export const projectGoalSavedSuccessfully = createAction(
  '[DOMAIN: CI Projects] (Project Goal) Saved Successfully',
  props<{ goal: ProjectGoal; project: CIProject }>(),
);

export const projectGoalRemovedById = createAction(
  '[DOMAIN: CI Projects] (Priority Goal) Removed',
  props<{ id: string }>(),
);

export const projectGoalDeleted = createAction(
  '[DOMAIN: CI Projects] (Priority Goal) Deleted',
  props<{ goal: ProjectGoal; project: CIProject }>(),
);
