import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { EntityState } from '~ci-portal/utils/entity-utils';
import { CIProject, HLC, ProjectFavorite, ProjectTeamMember } from '../models';
import { Milestone } from '../models/milestone.model';
import { ciProjectReducer } from './ci-project/ci-project.state';
import { hlcReducer } from './hlc/hlc.state';
import { milestoneReducer } from './milestones/milestones.state';
import { projectFavoritesReducer } from './project-favorites/favorites.state';
import { projectTeamMembersReducer } from './project-team-member/project-team-member.state';

export const FEATURE_NAME = 'ciProject';
export const featureState = createFeatureSelector<FeatureState>(FEATURE_NAME);

export interface FeatureState {
  ciProjects: EntityState<CIProject>;
  projectFavorites: EntityState<ProjectFavorite>;
  projectTeamMembers: EntityState<ProjectTeamMember>;
  milestones: EntityState<Milestone>;
  hlcs: EntityState<HLC>;
}

export const reducer: ActionReducerMap<FeatureState> = {
  ciProjects: ciProjectReducer,
  projectFavorites: projectFavoritesReducer,
  projectTeamMembers: projectTeamMembersReducer,
  milestones: milestoneReducer,
  hlcs: hlcReducer,
};
