import { createAction, props } from '@ngrx/store';
import { User } from '../models/user.model';

export const usersAdded = createAction(
  '[DOMAIN: Users] (Users) Added',
  props<{ users: User[] }>(),
);

export const usersModified = createAction(
  '[DOMAIN: Users] (Users) Modified',
  props<{ users: User[] }>(),
);

export const usersRemoved = createAction(
  '[DOMAIN: Users] (Users) Removed',
  props<{ users: User[] }>(),
);

