import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigationAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { format } from 'date-fns';
import { from } from 'rxjs';
import {
  filter,
  first,
  map,
  mergeMap,
  reduce,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { AuthenticatedUser, authenticatedUser } from '~ci-portal/core/auth';
import {
  purgeUnwantedProps,
  routeMatchesPath,
} from '~ci-portal/utils/common-utils';
import { setUpEntityChangeWatcherByRoutes } from '~ci-portal/utils/entity-utils';
import { SmartAction } from '../../models';
import {
  mySmartActionsChanged,
  mySmartActionsUnwatched,
  mySmartActionsWatched,
  smartActionDeleted,
  smartActionsAdded,
  smartActionSaved,
  smartActionSavedSuccessfully,
  smartActionSaving,
  smartActionsModified,
  smartActionsRemoved,
  smartActionsSaved,
} from './smart-action.actions';
import { SmartActionService } from './smart-action.service';

@Injectable()
export class SmartActionDataEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly smartActions: SmartActionService,
  ) {}

  unwatchMySmartActions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigationAction),
      routeMatchesPath('/'),
      map(() => mySmartActionsUnwatched()),
    ),
  );

  watchMySmartActions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigationAction),
      routeMatchesPath('/'),
      switchMap(() => this.store.select(authenticatedUser)),
      filter((user?: AuthenticatedUser): user is AuthenticatedUser => !!user),
      map(() => mySmartActionsWatched()),
    ),
  );

  updateSmartAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(smartActionSaved),
      withLatestFrom(this.store.select(authenticatedUser)),
      filter(([, user]) => !!user),
      map(
        ([{ action }, user]) =>
          ({
            ...action,
            archived: action.archived ?? false,
            id: action.id ?? uuidv4(),
            created: action.created ?? format(Date.now(), 'M/d/yyyy h:mm aa'),
            createdBy: action.createdBy ?? user!.displayName,
            modified: format(Date.now(), 'M/d/yyyy h:mm aa'),
            modifiedBy: user!.displayName,
          } as SmartAction),
      ),
      purgeUnwantedProps('ciProject', 'selected'),
      mergeMap(action =>
        this.smartActions
          .save(action)
          .pipe(map(() => smartActionSaving({ action }))),
      ),
      tap({ error: err => console.error(err) }),
    ),
  );

  saveSmartActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(smartActionsSaved),
        withLatestFrom(this.store.select(authenticatedUser)),
        filter(([, user]) => !!user),
        mergeMap(([{ actions }, user]) =>
          from(actions).pipe(
            map(
              action =>
                ({
                  ...action,
                  archived: action.archived ?? false,
                  created:
                    action.created ?? format(Date.now(), 'M/d/yyyy h:mm aa'),
                  createdBy: action.createdBy ?? user!.displayName,
                  modified: format(Date.now(), 'M/d/yyyy h:mm aa'),
                  modifiedBy: user!.displayName,
                } as SmartAction),
            ),
            purgeUnwantedProps(
              'ciProject',
              'selected',
              'isFavorite',
              'isSelected',
              'orgUnit',
            ),
            reduce(
              (updatedActions: SmartAction[], action) => [
                ...updatedActions,
                action,
              ],
              [],
            ),
            switchMap(actions => this.smartActions.saveMany(actions)),
          ),
        ),
      ),
    { dispatch: false },
  );

  correlateSavedSmartAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(smartActionSaving),
      mergeMap(({ action }) =>
        this.actions$.pipe(
          ofType(smartActionsAdded, smartActionsModified),
          map(({ actions }) =>
            actions.find(changed => changed.id === action.id),
          ),
          first((changed?: SmartAction): changed is SmartAction => !!changed),
          map(changed => smartActionSavedSuccessfully({ action: changed })),
        ),
      ),
    ),
  );

  delete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(smartActionDeleted),
        map(({ action }) => ({
          ...action,
        })),
        purgeUnwantedProps('ciProject', 'selected'),
        mergeMap(action => this.smartActions.archive(action)),
      ),
    { dispatch: false },
  );

  trackMySmartActions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mySmartActionsWatched),
      switchMap(() =>
        this.store.select(authenticatedUser).pipe(
          filter(
            (user?: AuthenticatedUser): user is AuthenticatedUser => !!user,
          ),
          switchMap(user =>
            this.smartActions.byAssignedEmail(user.email).pipe(
              map(actions => mySmartActionsChanged({ actions })),
              takeUntil(this.actions$.pipe(ofType(mySmartActionsUnwatched))),
            ),
          ),
          takeUntil(this.actions$.pipe(ofType(mySmartActionsUnwatched))),
        ),
      ),
    ),
  );

  watchEntityChanges$ = createEffect(() =>
    this.actions$.pipe(
      setUpEntityChangeWatcherByRoutes(
        this.actions$,
        this.smartActions,
        actions => smartActionsAdded({ actions }),
        actions => smartActionsModified({ actions }),
        actions => smartActionsRemoved({ actions }),
        '/ci-projects/edit',
        '/ci-projects/report',
        '/smart/team',
        '/smart/area',
        '/reports/smart-action-summary',
        '/admin/manage-smart-actions',
        '/admin',
      ),
    ),
  );
}
