import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FEATURE_NAME, reducer } from './store/feature.state';
import { GoalDataEffects } from './store/goals/goal-data.effects';
import { PriorityDataEffects } from './store/priorities/priority-data.effects';
import { StrategyDataEffects } from './store/strategies/strategy-data.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(FEATURE_NAME, reducer),
    EffectsModule.forFeature([
      GoalDataEffects,
      PriorityDataEffects,
      StrategyDataEffects,
    ]),
  ],
})
export class DomainsPlanningModule {}
