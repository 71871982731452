import { createAction, props } from '@ngrx/store';
import { OrgUnitDiv } from '../../models';

export const orgUnitDivsAdded = createAction(
  '[DOMAIN: Org Unit] (Divisions) Added',
  props<{ divisions: OrgUnitDiv[] }>(),
);

export const orgUnitDivsModified = createAction(
  '[DOMAIN: Org Unit] (Divisions) Modified',
  props<{ divisions: OrgUnitDiv[] }>(),
);

export const orgUnitDivsRemoved = createAction(
  '[DOMAIN: Org Unit] (Divisions) Removed',
  props<{ divisions: OrgUnitDiv[] }>(),
);

export const orgUnitDivSaved = createAction(
  '[DOMAIN: Org Unit] (Division) Saved',
  props<{ division: OrgUnitDiv }>(),
);

export const orgUnitDivDeleted = createAction(
  '[DOMAIN: Org Unit] (Division) Deleted',
  props<{ division: OrgUnitDiv }>(),
);
