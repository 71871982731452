import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, mergeEntities, removeEntities } from '~ci-portal/utils/entity-utils';
import { OrgUnitDiv } from '../../models';
import {
  orgUnitDivsAdded,
  orgUnitDivsModified,
  orgUnitDivsRemoved,
} from './org-unit-div.actions';

export const initialOrgUnitDivState: EntityState<OrgUnitDiv> = {
  entities: {},
  ids: [],
  pendingInitialLoad: true,
};

export const mergeOrgUnitDivs = (
  state: EntityState<OrgUnitDiv>,
  { divisions }: { divisions: OrgUnitDiv[] },
) => mergeEntities(state, divisions, 'id');

export const removeOrgUnitDivs = (
  state: EntityState<OrgUnitDiv>,
  { divisions }: { divisions: OrgUnitDiv[] },
) => removeEntities(state, divisions, 'id');

export const reduce = createReducer(
  initialOrgUnitDivState,
  on(orgUnitDivsAdded, orgUnitDivsModified, mergeOrgUnitDivs),
  on(orgUnitDivsRemoved, removeOrgUnitDivs),
);

export function orgUnitDivReducer(
  state = initialOrgUnitDivState,
  action: Action,
): EntityState<OrgUnitDiv> {
  return reduce(state, action);
}
