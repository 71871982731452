import { createSelector } from '@ngrx/store';
import { compose } from '~ci-portal/utils/common-utils';
import {
  EntityState,
  getEntities,
  getIds,
  getPendingInitialLoadFlag,
  renderEntitiesAsArray,
} from '~ci-portal/utils/entity-utils';
import { CIProject } from '../../models';
import { featureState, FeatureState } from '../feature.state';

export const getCIProjectState = (
  state: FeatureState,
): EntityState<CIProject> => state.ciProjects;

export const ciProjectState = createSelector(featureState, getCIProjectState);

export const ciProjectEntities = createSelector(ciProjectState, getEntities);

export const ciProjectIds = createSelector(ciProjectState, getIds);

export const allCIProjects = createSelector(
  ciProjectEntities,
  ciProjectIds,
  renderEntitiesAsArray,
);

export const ciProjectsPending = createSelector(
  ciProjectState,
  getPendingInitialLoadFlag,
);

export const findMaxPrettyId = (projects: CIProject[]): number =>
  projects.reduce(
    (prettyId, project) =>
      project.prettyId > prettyId ? project.prettyId : prettyId,
    0,
  );

export const chooseBasePrettyId = (max: number): number =>
  max < 1000 ? 999 : max;

export const incrementPrettyId = (base: number): number => base + 1;

export const computeNextPrettyId = (projects: CIProject[]): number =>
  compose(findMaxPrettyId, chooseBasePrettyId, incrementPrettyId)(projects);

export const nextPrettyId = createSelector(allCIProjects, computeNextPrettyId);
