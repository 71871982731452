import { ActivatedRouteSnapshot, Params } from '@angular/router';
import { routerNavigatedAction } from '@ngrx/router-store';
import { RouterNavigatedPayload } from '@ngrx/router-store/src/actions';
import { Action, createReducer, on } from '@ngrx/store';

export interface NavState {
  currentParams?: Params;
}

export const initialNavState = {};

export const aggregateAllParams = (
  params: Params,
  snapshot: ActivatedRouteSnapshot,
): Params =>
  snapshot.firstChild
    ? aggregateAllParams(
        {
          ...params,
          ...snapshot.params,
        },
        snapshot.firstChild,
      )
    : {
        ...params,
        ...snapshot.params,
      };

export const trackCurrentParams = (
  state: NavState,
  {
    payload: {
      routerState: { root },
    },
  }: { payload: RouterNavigatedPayload },
) => ({
  ...state,
  currentParams: aggregateAllParams(state.currentParams ?? {}, root),
});

const reduce = createReducer(
  initialNavState,
  on(routerNavigatedAction, trackCurrentParams),
);

export function navReducer(state = initialNavState, action: Action): NavState {
  return reduce(state, action);
}
