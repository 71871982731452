import { Action, ActionReducer } from '@ngrx/store';
import { userLoggedOut } from '~ci-portal/core/auth';
import { AppState } from './app.state';

export function onLogoutMetaReducer(
  reducer: ActionReducer<AppState>,
): ActionReducer<AppState> {
  return (state: AppState | undefined, action: Action) => (
    (state = action.type === userLoggedOut.type ? ({} as AppState) : state),
    reducer(state, action)
  );
}
