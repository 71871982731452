import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { EntityService } from '~ci-portal/utils/entity-utils';
import { UnitTeamMember } from '../../models';

@Injectable({ providedIn: 'root' })
export class UnitTeamsService extends EntityService<UnitTeamMember> {
  constructor(firestore: AngularFirestore) {
    super('spUnitTeams', 'id', firestore);
  }
}
