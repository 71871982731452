import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';

export const purgeUnwantedProps = <T>(...props: (keyof T | string)[]) =>
  pipe(
    map((value: T) => JSON.parse(JSON.stringify(value))),
    map(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (value: any) =>
        props.reduce((value, prop) => (delete value[prop], value), value) as T,
    ),
  );
