import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { EntityService } from '~ci-portal/utils/entity-utils';
import { Priority } from '../../models';

@Injectable({ providedIn: 'root' })
export class PriorityService extends EntityService<Priority> {
  constructor(firestore: AngularFirestore) {
    super('spPriority', 'id', firestore, 'key');
  }
}
