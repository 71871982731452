import { createSelector } from '@ngrx/store';
import {
  EntityState,
  getEntities,
  getIds,
  getPendingInitialLoadFlag,
  renderEntitiesAsArray,
} from '~ci-portal/utils/entity-utils';
import { SmartAction } from '../../models';
import { featureState, FeatureState } from '../feature.state';

export const getSmartActionState = (
  state: FeatureState,
): EntityState<SmartAction> => state.smartActions;

export const smartActionState = createSelector(
  featureState,
  getSmartActionState,
);

export const smartActionEntities = createSelector(
  smartActionState,
  getEntities,
);

export const smartActionIds = createSelector(smartActionState, getIds);

export const allSmartActions = createSelector(
  smartActionEntities,
  smartActionIds,
  renderEntitiesAsArray,
);

export const nonArchivedSmartActions = createSelector(
  allSmartActions,
  (actions: SmartAction[]) =>
    actions.filter(action => action.archived !== true),
);

export const archivedSmartActions = createSelector(
  allSmartActions,
  (actions: SmartAction[]) =>
    actions.filter(action => action.archived === true),
);

export const dateParseRegexp = new RegExp(/(\d{2})\/(\d{2})\/(\d{4})/);

export const sortedSmartActions = createSelector(
  nonArchivedSmartActions,
  (actions: SmartAction[]) =>
    actions.sort((a, b) =>
      (
        (a.timeBound?.replace(dateParseRegexp, '$3-$1-$2') ?? '9999-99-99') +
        ':' +
        a.specific
      ).localeCompare(
        (b.timeBound?.replace(dateParseRegexp, '$3-$1-$2') ?? '9999-99-99') +
          ':' +
          b.specific,
      ),
    ),
);

export const smartActionsPending = createSelector(
  smartActionState,
  getPendingInitialLoadFlag,
);
