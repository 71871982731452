import { createAction, props } from '@ngrx/store';
import { CIProject, CIProjectStage } from '../../models/ci-project.model';

export const ciProjectSaved = createAction(
  '[DOMAIN: CI Projects] (CI Project) Saved',
  props<{ project: Partial<CIProject>; skipUser?: boolean }>(),
);

export const ciProjectsSaved = createAction(
  '[DOMAIN: CI Projects] (CI Projects) Saved',
  props<{ projects: CIProject[] }>(),
);

export const ciProjectSavedSuccessfully = createAction(
  '[DOMAIN: CI Projects] (CI Project) Saved Successfully',
  props<{ project: CIProject; isNew?: boolean; skipUser?: boolean }>(),
);
export const ciProjectStageSaved = createAction(
  '[DOMAIN: CI Projects] (CI Project Stage) Saved',
  props<{ stage: CIProjectStage; project: CIProject }>(),
);

export const ciProjectDeleted = createAction(
  '[DOMAIN: CI Projects] (CI Project) Deleted',
  props<{ project: CIProject }>(),
);

export const ciProjectsDeleted = createAction(
  '[DOMAIN: CI Projects] (CI Projects) Deleted',
  props<{ projects: CIProject[] }>(),
);
