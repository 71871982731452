import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, mergeMap } from 'rxjs/operators';
import { CIProjectService } from './ci-project.service';
import { projectLinkDeleted, projectLinkSaved, projectLinkSavedSuccessfully } from './project-link.actions';

@Injectable({ providedIn: 'root' })
export class ProjectLinkDataEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly projects: CIProjectService,
  ) {}

  addProjectLink$ = createEffect(() =>
    this.actions$.pipe(
      ofType(projectLinkSaved),
      mergeMap(({ project, link }) =>
        this.projects
          .addLink(link, project)
          .pipe(map(() => projectLinkSavedSuccessfully())),
      ),
    ),
  );

  removeProjectLinks$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(projectLinkDeleted),
        mergeMap(({ project, link }) =>
          this.projects.deleteLink(link, project),
        ),
      ),
    { dispatch: false },
  );
}
