import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthenticatedUser } from '~ci-portal/core/auth';
import { ModuleArea, SiteModule } from '../../site-modules';

@Component({
  selector: 'cip-site-nav',
  templateUrl: './site-nav.component.html',
  styleUrls: ['./site-nav.component.scss'],
})
export class SiteNavComponent {
  @Input() modules?: SiteModule[];
  @Input() currentUrl?: string | null = '';
  @Input() authenticatedUser?: AuthenticatedUser | null;
  @Output() moduleSelected = new EventEmitter<SiteModule>();
  @Output() areaSelected = new EventEmitter<{ module: SiteModule, area: ModuleArea }>();
}
