import { Action, createReducer, on } from '@ngrx/store';
import { applyReductions } from '~ci-portal/utils/common-utils';
import {
  EntityState,
  mergeEntities,
  removeEntities,
} from '~ci-portal/utils/entity-utils';
import { SmartAction } from '../../models';
import {
  mySmartActionsChanged,
  smartActionsAdded,
  smartActionsModified,
  smartActionsRemoved,
} from './smart-action.actions';

export const initialSmartActionState: EntityState<SmartAction> = {
  entities: {},
  ids: [],
  pendingInitialLoad: true,
};

export const mergeSmartActions = (
  state: EntityState<SmartAction>,
  { actions }: { actions: SmartAction[] },
) => mergeEntities(state, actions, 'id');

export const removeSmartActions = (
  state: EntityState<SmartAction>,
  { actions }: { actions: SmartAction[] },
) => removeEntities(state, actions, 'id');

export const clearSmartActions = (state: EntityState<SmartAction>) => ({
  ...state,
  entities: {},
  ids: [],
});

export const reduce = createReducer(
  initialSmartActionState,
  on(smartActionsAdded, smartActionsModified, mergeSmartActions),
  on(smartActionsRemoved, removeSmartActions),
  on(
    mySmartActionsChanged,
    applyReductions(clearSmartActions, mergeSmartActions),
  ),
);

export function smartActionReducer(
  state = initialSmartActionState,
  action: Action,
): EntityState<SmartAction> {
  return reduce(state, action);
}
