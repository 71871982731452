import { Observable, of } from 'rxjs';
import { map, retry, withLatestFrom } from 'rxjs/operators';

export const generateCollisionFreeRandomId = (
  existingId: string | null | undefined,
  buildId: (id: string) => string,
  otherIds: Observable<string[]>,
  rangeMin = 0,
  rangeMax = 10000
) =>
  of(0).pipe(
    map(() => Math.floor(rangeMin + (Math.random() * (rangeMax - rangeMin)))),
    map(rand => buildId(rand.toString())),
    withLatestFrom(otherIds),
    map(([id, possibles]) => {
      if (existingId) {
        return existingId;
      }

      // When it comes to rxjs retry, errors have to be thrown a certain way!! :'(
      if (possibles.includes(id)) {
        throw new Error('Collision');
      }
      return id;
    }),
    retry(100),
  );
