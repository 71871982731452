export enum SmartActionState {
  draft = "Draft",
  active = "Active",
  hold = "Hold",
  done = "Done",
  unknown = "",
}

export interface SmartAction {
  id?: string;
  ciProjectID?: string;
  spGoalStrategyID: string;
  spOrgUnitID: string;
  assignedEmail: string;
  assignedName: string;
  measure: string;
  specific: string;
  notes?: string;
  state: SmartActionState;
  timeBound?: string;
  realistic: boolean | string;
  archived: boolean;
  created: string;
  createdBy: string;
  modified: string;
  modifiedBy: string;
}
