import { Action, createReducer, on } from '@ngrx/store';
import { applyReductions } from '~ci-portal/utils/common-utils';
import {
  EntityState,
  mergeEntities,
  removeEntities,
} from '~ci-portal/utils/entity-utils';
import { ProjectTeamMember } from '../../models';
import {
  projectTeamMembersAdded,
  projectTeamMembersChanged,
  projectTeamMembersModified,
  projectTeamMembersRemoved,
} from './project-team-member.actions';

export const initialProjectTeamMembersState: EntityState<ProjectTeamMember> = {
  entities: {},
  ids: [],
  pendingInitialLoad: true,
};

export const mergeProjectTeamMembers = (
  state: EntityState<ProjectTeamMember>,
  { teamMembers }: { teamMembers: ProjectTeamMember[] },
) => mergeEntities(state, teamMembers, 'id');

export const removeProjectTeamMembers = (
  state: EntityState<ProjectTeamMember>,
  { teamMembers }: { teamMembers: ProjectTeamMember[] },
) => removeEntities(state, teamMembers, 'id');

export const clearProjectTeamMembers = (
  state: EntityState<ProjectTeamMember>,
) => ({
  ...state,
  entities: {},
  ids: [],
});

export const reduce = createReducer(
  initialProjectTeamMembersState,
  on(
    projectTeamMembersAdded,
    projectTeamMembersModified,
    mergeProjectTeamMembers,
  ),
  on(projectTeamMembersRemoved, removeProjectTeamMembers),
  on(
    projectTeamMembersChanged,
    applyReductions(clearProjectTeamMembers, mergeProjectTeamMembers),
  ),
);

export function projectTeamMembersReducer(
  state = initialProjectTeamMembersState,
  action: Action,
): EntityState<ProjectTeamMember> {
  return reduce(state, action);
}
