import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { EntityService } from '~ci-portal/utils/entity-utils';
import { OrgUnitArea } from '../../models';

@Injectable({ providedIn: 'root' })
export class OrgUnitAreaService extends EntityService<OrgUnitArea> {
  constructor(firestore: AngularFirestore) {
    super('spOrgUnitArea', 'id', firestore);
  }
}
