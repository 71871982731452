import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthenticatedUser } from '~ci-portal/core/auth';

@Component({
  selector: 'cip-authenticated-user-badge',
  templateUrl: './authenticated-user-badge.component.html',
  styleUrls: ['./authenticated-user-badge.component.scss'],
})
export class AuthenticatedUserBadgeComponent {
  @Input() user?: AuthenticatedUser | null;
  @Output() logOut = new EventEmitter();
  @Output() administer = new EventEmitter();
  opened = false;
}
