import { createAction, props } from '@ngrx/store';
import { SmartFavorite } from '../../models/smart-favorite.model';

export const smartFavoritesChanged = createAction(
  '[DOMAIN: Smart Actions] (Favorites) Changed',
  props<{ favorites: SmartFavorite[] }>(),
);

export const smartFavoriteToggling = createAction(
  '[DOMAIN: Smart Actions] (Favorites) Toggling',
  props<{ orgUnitId: string }>(),
);
