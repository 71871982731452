import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { first, map, mergeMap } from 'rxjs/operators';
import { CIProject } from '../../models';
import { ciProjectsModified } from './ci-project.actions';
import { CIProjectService } from './ci-project.service';
import {
  projectGoalDeleted,
  projectGoalSaved,
  projectGoalSavedSuccessfully,
  projectGoalSaving,
} from './project-goal.actions';

@Injectable({ providedIn: 'root' })
export class ProjectGoalDataEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly projects: CIProjectService,
  ) {
  }

  addProjectGoal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(projectGoalSaved),
      mergeMap(({ goal, project }) =>
        this.projects
          .addPriorityGoal(goal, project)
          .pipe(map(() => projectGoalSaving({ goal, project }))),
      ),
    ),
  );

  correlateSavedProjectGoal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(projectGoalSaving),
      mergeMap(({ goal, project }) =>
        this.actions$.pipe(
          ofType(ciProjectsModified),
          map(({ projects }) =>
            projects.find(changed => changed.projectId === project.projectId),
          ),
          // eslint-disable-next-line rxjs/no-unsafe-first
          first(
            (changed?: CIProject): changed is CIProject =>
              !!changed &&
              !!changed.goals?.length &&
              changed.goals.some(
                g =>
                  g.spGoalId == goal.spGoalId &&
                  g.spPriorityId === goal.spPriorityId,
              ),
          ),
          map(changed =>
            projectGoalSavedSuccessfully({ goal, project: changed }),
          ),
        ),
      ),
    ),
  );

  deleteProjectGoalById$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(projectGoalDeleted),
        mergeMap(({ project, goal }) =>
          this.projects.deletePriorityGoal(goal, project),
        ),
      ),
    { dispatch: false },
  );
}
