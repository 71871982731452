import { createAction, props } from '@ngrx/store';
import { CIProject, ProjectFavorite } from '../../models';

export const favoritesChanged = createAction(
  '[DOMAIN: CI-Project] (Project Favorites) Changed',
  props<{ favorites: ProjectFavorite[] }>(),
);

export const projectFavoriteToggled = createAction(
  '[DOMAIN: CI-Project] (Project Favorite) Toggled',
  props<{ project: CIProject }>(),
);
