import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap } from 'rxjs/operators';
import { setUpEntityChangeWatcherByRoutes } from '~ci-portal/utils/entity-utils';
import { goalDeleted, goalsAdded, goalSaved, goalsModified, goalsRemoved } from './goal.actions';
import { GoalService } from './goal.service';

@Injectable({ providedIn: 'root' })
export class GoalDataEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly goals: GoalService,
  ) {}

  watchEntityChanges$ = createEffect(() =>
    this.actions$.pipe(
      setUpEntityChangeWatcherByRoutes(
        this.actions$,
        this.goals,
        goals => goalsAdded({ goals }),
        goals => goalsModified({ goals }),
        goals => goalsRemoved({ goals }),
        '/ci-projects/edit',
        '/ci-projects/report',
        '/smart/team',
        '/smart/area',
        '/reports/smart-action-summary',
        '/admin/manage-goals',
        '/admin'
      ),
    ),
  );

  saveGoal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(goalSaved),
      mergeMap(({ goal }) => this.goals.save(goal)),
    ),
    { dispatch: false },
  );

  deleteGoal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(goalDeleted),
      mergeMap(({ goal }) => this.goals.delete(goal)),
    ),
    { dispatch: false }
  );
}
