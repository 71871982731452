// eslint-disable-next-line @typescript-eslint/no-empty-interface
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';

export interface AppState {
  router: RouterReducerState;
}

export const reducer: ActionReducerMap<AppState> = {
  router: routerReducer,
};
