import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { EntityState } from '~ci-portal/utils/entity-utils';
import { Goal, Priority, Strategy } from '../models';
import { goalReducer } from './goals/goal.state';
import { priorityReducer } from './priorities/priority.state';
import { strategyReducer } from './strategies/strategy.state';

export const FEATURE_NAME = 'planning';
export const featureState = createFeatureSelector<FeatureState>(FEATURE_NAME);

export interface FeatureState {
  goals: EntityState<Goal>;
  priorities: EntityState<Priority>;
  strategies: EntityState<Strategy>;
}

export const reducer: ActionReducerMap<FeatureState> = {
  goals: goalReducer,
  priorities: priorityReducer,
  strategies: strategyReducer,
};
