import { createSelector } from '@ngrx/store';
import { EntityDictionary, EntityIds } from '~ci-portal/utils/entity-utils';
import { SmartFavorite } from '../../models/smart-favorite.model';
import { featureState, FeatureState } from '../feature.state';
import { SmartFavoritesState } from './smart-favorites.state';

export const getFavoritesState = (state: FeatureState): SmartFavoritesState =>
  state.favorites;

export const getEntities = (state: SmartFavoritesState): EntityDictionary<SmartFavorite> =>
  state.entities;

export const getIds = (state: SmartFavoritesState): EntityIds =>
  state.ids;

export const renderEntitiesAsArray = (entities: EntityDictionary<SmartFavorite>, ids: EntityIds): SmartFavorite[] =>
  ids.map(id => entities[id]) as SmartFavorite[];

export const favoritesState = createSelector(
  featureState,
  getFavoritesState
);

export const favoritesEntities = createSelector(
  favoritesState,
  getEntities
);

export const favoritesIds = createSelector(
  favoritesState,
  getIds
);

export const allFavorites = createSelector(
  favoritesEntities,
  favoritesIds,
  renderEntitiesAsArray
);
