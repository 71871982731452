import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import {
  allCurrentParams,
  areaSelected,
  moduleSelected,
} from '~ci-portal/components/layout';

@Injectable()
export class NavigationEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly router: Router,
  ) {}

  routeToModuleWithUrl$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(moduleSelected),
        filter(({ module }) => !!module.url),
        tap(({ module }) => this.router.navigate([module.url!])),
      ),
    { dispatch: false },
  );

  routeToModuleArea$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(moduleSelected),
        filter(({ module }) => !!module.areas?.length),
        tap(({ module }) =>
          !!module.baseUrl &&
          this.router.routerState.snapshot.url.startsWith(module.baseUrl)
            ? void 0
            : this.router.navigate([module.areas![0].url]),
        ),
      ),
    { dispatch: false },
  );

  routeToArea$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(areaSelected),
        switchMap(({ area }) =>
          area.url.includes(':')
            ? of(area.url).pipe(
                map(url => url.split(':')),
                switchMap(([path, paramName]) =>
                  this.store.select(allCurrentParams).pipe(
                    take(1),
                    // eslint-disable-next-line ngrx/avoid-mapping-selectors
                    map(params =>
                      params?.[paramName]
                        ? `${path}/${params[paramName]}`
                        : area.fallbackUrl,
                    ),
                  ),
                ),
              )
            : of(area.url),
        ),
        tap(url => this.router.navigate([url])),
      ),
    { dispatch: false },
  );
}
