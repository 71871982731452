import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { EntityState } from '~ci-portal/utils/entity-utils';
import { OrgUnit, OrgUnitArea, OrgUnitDiv } from '../models';
import { orgUnitAreaReducer } from './org-unit-area/org-unit-area.state';
import { orgUnitDivReducer } from './org-unit-div/org-unit-div.state';
import { orgUnitReducer } from './org-unit/org-unit.state';

export const FEATURE_NAME = 'orgUnit';
export const featureState = createFeatureSelector<FeatureState>(FEATURE_NAME);

export interface FeatureState {
  orgUnitDivs: EntityState<OrgUnitDiv>;
  orgUnitAreas: EntityState<OrgUnitArea>;
  orgUnits: EntityState<OrgUnit>;
}

export const reducer: ActionReducerMap<FeatureState> = {
  orgUnitDivs: orgUnitDivReducer,
  orgUnitAreas: orgUnitAreaReducer,
  orgUnits: orgUnitReducer,
};
