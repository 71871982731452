import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { combineLatest, from, Observable, throwError } from 'rxjs';
import { catchError, map, scan } from 'rxjs/operators';
import { CiEnvironment, ENVIRONMENT } from '~ci-portal/core/environment';
import { partition } from '~ci-portal/utils/common-utils';
import { EntityService } from '~ci-portal/utils/entity-utils';
import {
  CIProject,
  CIProjectStage,
  ProjectDetail,
  ProjectGoal,
  ProjectLink,
} from '../../models';

const FieldValue = firebase.firestore.FieldValue;
const COLLECTION_NAME = 'ciProject';

@Injectable({ providedIn: 'root' })
export class CIProjectService extends EntityService<CIProject> {
  constructor(
    firestore: AngularFirestore,
    private readonly http: HttpClient,
    @Inject(ENVIRONMENT) private readonly env: CiEnvironment,
  ) {
    super(COLLECTION_NAME, 'projectId', firestore);
  }

  byProjectIds(projectIds: string[]): Observable<CIProject[]> {
    return combineLatest(
      projectIds
        .reduce(partition(), [])
        .map(projectIdChunk =>
          this.firestore
            .collection<CIProject>(COLLECTION_NAME, ref =>
              ref.where('projectId', 'in', projectIdChunk),
            )
            .valueChanges({ idField: 'id' }),
        ),
    ).pipe(scan((all: CIProject[], chunks) => chunks.flat(), []));
  }

  projectExists(projectId: string): Observable<boolean> {
    return this.col()
      .doc(projectId)
      .get()
      .pipe(map(doc => doc.exists));
  }

  getPrettyID(): Observable<number> {
    const increment = FieldValue.increment(1);
    const counterRef = this.firestore.firestore.collection('ciProjectCounter').doc('counter');

    return from(this.firestore.firestore.runTransaction(transaction => {
      return transaction.get(counterRef).then(counterDoc => {
        const count = counterDoc.get('count') as number;
        transaction.update(counterRef, {
          count: increment
        });

        return count;
      })
    }));
  }

  saveStage(stage: CIProjectStage, project: CIProject): Observable<void> {
    return from(
      this.col()
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .doc(project[this.keyProp])
        .update({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          stage,
        }),
    );
  }

  saveDetails(detail: ProjectDetail, project: CIProject): Observable<void> {
    return from(
      this.col()
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .doc(project[this.keyProp])
        .update({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          detail,
        }),
    );
  }

  addPriorityGoal(goal: ProjectGoal, project: CIProject): Observable<void> {
    return from(
      this.col()
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .doc(project[this.keyProp])
        .update({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          goals: FieldValue.arrayUnion(goal),
        }),
    );
  }

  deletePriorityGoal(goal: ProjectGoal, project: CIProject): Observable<void> {
    return from(
      this.col()
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .doc(project[this.keyProp])
        .update({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          goals: FieldValue.arrayRemove(goal),
        }),
    );
  }

  addLink(link: ProjectLink, project: CIProject): Observable<void> {
    return from(
      this.col()
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .doc(project[this.keyProp])
        .update({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          links: FieldValue.arrayUnion(link),
        }),
    );
  }

  deleteLink(link: ProjectLink, project: CIProject): Observable<void> {
    return from(
      this.col()
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .doc(project[this.keyProp])
        .update({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          links: FieldValue.arrayRemove(link),
        }),
    );
  }

  addHLC(hlc: string, project: CIProject): Observable<void> {
    return from(
      this.col()
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .doc(project[this.keyProp])
        .update({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          hlc: FieldValue.arrayUnion(hlc),
        }),
    );
  }

  deleteHLC(hlc: string, project: CIProject): Observable<void> {
    return from(
      this.col()
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .doc(project[this.keyProp])
        .update({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          hlc: FieldValue.arrayRemove(hlc),
        }),
    );
  }

  saveMany(projects: CIProject[]): Observable<void> {
    const batch = this.firestore.firestore.batch();
    projects.forEach(project =>
      batch.set(
        this.col().doc((project[this.keyProp] as string).toString()).ref,
        project,
      ),
    );
    return from(batch.commit());
  }

  deleteMany(projects: CIProject[]): Observable<void> {
    const batch = this.firestore.firestore.batch();
    projects.forEach(project =>
      batch.delete(
        this.col().doc((project[this.keyProp] as string).toString()).ref,
      ),
    );
    return from(batch.commit());
  }
}
