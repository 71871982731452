import { createAction, props } from '@ngrx/store';
import { CIProject, ProjectLink } from '../../models';

export const projectLinkSaved = createAction(
  '[DOMAIN: CI Projects] (Project Link) Saved',
  props<{ link: ProjectLink, project: CIProject }>(),
);

export const projectLinkSavedSuccessfully = createAction(
  '[DOMAIN: CI Projects] (Project Link) Saved Successfully',
);

export const projectLinkDeleted = createAction(
  '[DOMAIN: CI Projects] (Project Link) Deleted',
  props<{ link: ProjectLink, project: CIProject }>(),
);
