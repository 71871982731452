import { Action, createReducer, on } from '@ngrx/store';
import {
  EntityState,
  mergeEntities,
  removeEntities,
} from '~ci-portal/utils/entity-utils';
import { UnitTeamMember } from '../../models';
import {
  unitTeamsAdded,
  unitTeamsModified,
  unitTeamsRemoved,
} from './unit-team.actions';

export const initialUnitTeamsState: EntityState<UnitTeamMember> = {
  entities: {},
  ids: [],
  pendingInitialLoad: true,
};

export const mergeUnitTeams = (
  state: EntityState<UnitTeamMember>,
  { teamMembers }: { teamMembers: UnitTeamMember[] },
) => mergeEntities(state, teamMembers, 'id');

export const removeUnitTeams = (
  state: EntityState<UnitTeamMember>,
  { teamMembers }: { teamMembers: UnitTeamMember[] },
) => removeEntities(state, teamMembers, 'id');

export const reduce = createReducer(
  initialUnitTeamsState,
  on(unitTeamsAdded, unitTeamsModified, mergeUnitTeams),
  on(unitTeamsRemoved, removeUnitTeams),
);

export function unitTeamsReducer(
  state = initialUnitTeamsState,
  action: Action,
): EntityState<UnitTeamMember> {
  return reduce(state, action);
}
