import { AngularFirestore } from '@angular/fire/compat/firestore';
import { HLC } from '../../models';
import { Injectable } from '@angular/core';
import { EntityService } from '~ci-portal/utils/entity-utils';

@Injectable({ providedIn: 'root' })
export class HLCService extends EntityService<HLC> {
  constructor(firestore: AngularFirestore) {
    super('ciHLC', 'id', firestore);
  }
}
