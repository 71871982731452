import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { from, Observable } from 'rxjs';
import { EntityService } from '~ci-portal/utils/entity-utils';
import { SmartAction } from '../../models';

const COLLECTION_NAME = 'spSmartActions';

@Injectable({ providedIn: 'root' })
export class SmartActionService extends EntityService<SmartAction> {
  constructor(firestore: AngularFirestore) {
    super(COLLECTION_NAME, 'id', firestore);
  }

  byAssignedEmail(email: string): Observable<SmartAction[]> {
    return this.firestore
      .collection<SmartAction>(COLLECTION_NAME, ref =>
        ref.where('assignedEmail', '==', email),
      )
      .valueChanges({ idField: 'id' });
  }

  archive(action: SmartAction): Observable<void> {
    return this.save({ ...action, archived: true });
  }

  saveMany(actions: SmartAction[]): Observable<void> {
    const batch = this.firestore.firestore.batch();
    actions.forEach(action =>
      batch.set(
        this.col().doc((action[this.keyProp] as string).toString()).ref,
        action,
      ),
    );
    return from(batch.commit());
  }

  deleteMany(actions: SmartAction[]): Observable<void> {
    const batch = this.firestore.firestore.batch();
    actions.forEach(action =>
      batch.delete(
        this.col().doc((action[this.keyProp] as string).toString()).ref,
      ),
    );
    return from(batch.commit());
  }
}
