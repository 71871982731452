import { Action, createReducer, on } from '@ngrx/store';
import { applyReductions } from '~ci-portal/utils/common-utils';
import {
  EntityState,
  mergeEntities,
  removeEntities,
} from '~ci-portal/utils/entity-utils';
import { Milestone } from '../../models/milestone.model';
import {
  milestonesAdded,
  milestoneSaved,
  milestonesModified,
  milestonesRemoved,
  myProjectMilestonesChanged,
  projectMilestonesChanged,
  projectMilestonesUnwatched,
} from './milestones.actions';

export interface MilestonesState extends EntityState<Milestone> {
  currentProjectMilestones?: Milestone[];
}

export const initialMilestoneState: MilestonesState = {
  entities: {},
  ids: [],
  pendingInitialLoad: true,
};

export const mergeMilestones = (
  state: MilestonesState,
  { milestones }: { milestones: Milestone[] },
) => mergeEntities(state, milestones, 'id');

export const removeMilestones = (
  state: MilestonesState,
  { milestones }: { milestones: Milestone[] },
) => removeEntities(state, milestones, 'id');

export const updateMilestone = (
  state: MilestonesState,
  { milestone }: { milestone: Milestone },
) =>
  milestone.id
    ? {
        ...state,
        entities: {
          ...state.entities,
          [milestone.id]: milestone,
        },
      }
    : state;

export const clearMyProjectMilestones = (state: MilestonesState) => ({
  ...state,
  entities: {},
  ids: [],
});

export const trackProjectMilestones = (
  state: MilestonesState,
  { milestones }: { milestones: Milestone[] },
) => ({
  ...state,
  currentProjectMilestones: [...milestones].sort((a, b) => a.order - b.order),
});

export const clearProjectMilestones = (state: MilestonesState) => ({
  ...state,
  currentProjectMilestones: undefined,
});

export const reduce = createReducer(
  initialMilestoneState,
  on(milestonesAdded, milestonesModified, mergeMilestones),
  on(milestonesRemoved, removeMilestones),
  on(milestoneSaved, updateMilestone),
  on(
    myProjectMilestonesChanged,
    applyReductions(clearMyProjectMilestones, mergeMilestones),
  ),
  on(projectMilestonesChanged, trackProjectMilestones),
  on(projectMilestonesUnwatched, clearProjectMilestones),
);

export function milestoneReducer(
  state = initialMilestoneState,
  action: Action,
): MilestonesState {
  return reduce(state, action);
}
