import { createAction, props } from '@ngrx/store';
import { Priority } from '../../models';

export const prioritiesLoaded = createAction(
  '[STORE: Planning] (Priorities) Loaded',
  props<{ priorities: Priority[] }>(),
);

export const prioritiesAdded = createAction(
  '[STORE: Planning] (Priorities) Added',
  props<{ priorities: Priority[] }>(),
);

export const prioritiesModified = createAction(
  '[STORE: Planning] (Priorities) Modified',
  props<{ priorities: Priority[] }>(),
);

export const prioritiesRemoved = createAction(
  '[STORE: Planning] (Priorities) Removed',
  props<{ priorities: Priority[] }>(),
);

export const prioritySaved = createAction(
  '[STORE: Planning] (Priority) Saved',
  props<{ priority: Priority }>(),
)

export const priorityDeleted = createAction(
  '[STORE: Planning] (Priority) Deleted',
  props<{ priority: Priority }>(),
);
