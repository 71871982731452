import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  routerNavigatedAction,
  routerNavigationAction,
} from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import moize from 'moize';
import { merge } from 'rxjs';
import {
  exhaustMap,
  filter,
  map, mergeMap,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import {
  AuthenticatedUser,
  authenticatedUser,
  userLoggingOut,
} from '~ci-portal/core/auth';
import {
  routeNotStartsWithPath,
  routeStartsWithPath,
  sha1,
} from '~ci-portal/utils/common-utils';
import { favoritesChanged, projectFavoriteToggled } from './favorites.actions';
import { projectFavoritesIds } from './favorites.selectors';
import { ProjectFavoritesService } from './favorites.service';

export const formatFavoriteId = moize(
  (projectId: string, email: string) => `${projectId}-${sha1(email)}`,
);

@Injectable()
export class ProjectFavoriteEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly favorites: ProjectFavoritesService,
  ) {}

  preloadUserFavorites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigationAction),
      routeStartsWithPath('/ci-projects/list', '/ci-projects/edit'),
      switchMap(() => this.store.select(authenticatedUser)),
      filter((user): user is AuthenticatedUser => !!user),
      exhaustMap(({ email }) =>
        this.favorites.byEmail(email).pipe(
          map(favorites => favoritesChanged({ favorites })),
          takeUntil(
            merge(
              this.actions$.pipe(ofType(userLoggingOut)),
              this.actions$.pipe(
                ofType(routerNavigatedAction),
                routeNotStartsWithPath(
                  '/ci-projects/list',
                  '/ci-projects/edit',
                ),
              ),
            ),
          ),
        ),
      ),
    ),
  );

  favoriteCIProject$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(projectFavoriteToggled),
        withLatestFrom(
          this.store.select(authenticatedUser),
          this.store.select(projectFavoritesIds),
        ),
        filter(([, user]) => !!user),
        filter(
          ([{ project }, user, ids]) =>!ids.includes(formatFavoriteId(project.projectId, user!.email)),
        ),
        map(([{ project }, user]) => ({
            id: formatFavoriteId(project.projectId, user!.email),
            projectId: project.projectId,
            email: user!.email ?? undefined,
            name: user!.displayName ?? undefined,
        })),
        mergeMap(favorite => this.favorites.add(favorite)),
      ),
    { dispatch: false },
  );

  unfavoriteCIProject$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(projectFavoriteToggled),
        withLatestFrom(
          this.store.select(authenticatedUser),
          this.store.select(projectFavoritesIds),
        ),
        filter(([, user]) => !!user),
        filter(([{ project }, user, ids]) =>
          ids.includes(formatFavoriteId(project.projectId, user!.email)),
        ),
        map(([{ project }, user]) => {
          return {
            id: formatFavoriteId(project.projectId, user!.email),
            projectId: project.projectId,
            email: user!.email ?? undefined,
            name: user!.displayName ?? undefined,
          };
        }),
        mergeMap(favorite => this.favorites.remove(favorite)),
      ),
    { dispatch: false },
  );
}
