import { Pipe, PipeTransform } from '@angular/core';
import * as UrlPattern from 'url-pattern';
import { ModuleArea } from '../site-modules';

@Pipe({ name: 'isActiveArea' })
export class IsActiveAreaPipe implements PipeTransform {
  transform(area: ModuleArea, currentUrl?: string | null): boolean {
    const pattern = new UrlPattern(area.url);
    return !!(currentUrl && pattern.match(currentUrl));
  }
}
