import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { PERSISTENCE } from '@angular/fire/compat/auth';
import { canActivate } from '@angular/fire/compat/auth-guard';
import { MatButtonModule } from '@angular/material/button';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ComponentsLayoutModule } from '~ci-portal/components/layout';
import { appPreInitializing } from '~ci-portal/core/app';
import { AuthModule } from '~ci-portal/core/auth';
import { EnvironmentModule } from '~ci-portal/core/environment';
import { DomainsCIProjectModule } from '~ci-portal/domains/ci-project';
import { DomainsOrgUnitModule } from '~ci-portal/domains/org-unit';
import { DomainsPlanningModule } from '~ci-portal/domains/planning';
import { DomainsSmartActionsModule } from '~ci-portal/domains/smart-actions';
import { DomainsUserModule } from '~ci-portal/domains/user';
import { SharedModule } from '~ci-portal/shared';
import {
  redirectLoggedInToRoot,
  redirectUnauthorizedToLogIn,
} from '~ci-portal/utils/common-utils';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { reducer } from './store/app.state';
import { AuthEffects } from './store/auth.effects';
import { NavigationEffects } from './store/navigation.effects';
import { onLogoutMetaReducer } from './store/on-logout.metareducer';

export const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('~ci-portal/pages/login').then(m => m.PagesLoginModule),
    ...canActivate(redirectLoggedInToRoot),
  },
  {
    path: 'smart/teams',
    loadChildren: () =>
      import('~ci-portal/pages/smart-teams').then(m => m.PagesSmartTeamsModule),
    ...canActivate(redirectUnauthorizedToLogIn),
  },
  {
    path: 'smart/team/:orgUnitId',
    loadChildren: () =>
      import('~ci-portal/pages/smart-action-entry').then(
        m => m.PagesSmartActionEntryModule,
      ),
    ...canActivate(redirectUnauthorizedToLogIn),
  },
  {
    path: 'smart/area/:areaId',
    loadChildren: () =>
      import('~ci-portal/pages/smart-action-entry').then(
        m => m.PagesSmartActionEntryModule,
      ),
    ...canActivate(redirectUnauthorizedToLogIn),
  },
  {
    path: 'ci-projects/list',
    loadChildren: () =>
      import('~ci-portal/pages/ci-project-listing').then(
        m => m.PagesCIProjectListingModule,
      ),
    ...canActivate(redirectUnauthorizedToLogIn),
  },
  {
    path: 'ci-projects/create',
    loadChildren: () =>
      import('~ci-portal/pages/create-ci-project').then(
        m => m.PagesCreateCIProjectModule,
      ),
    ...canActivate(redirectUnauthorizedToLogIn),
  },
  {
    path: 'ci-projects/edit/:projectId',
    loadChildren: () =>
      import('~ci-portal/pages/ci-project-edit').then(
        m => m.PagesCIProjectEditModule,
      ),
    ...canActivate(redirectUnauthorizedToLogIn),
  },
  {
    path: 'ci-projects/report/:projectId',
    loadChildren: () =>
      import('~ci-portal/pages/ci-project-report').then(
        m => m.PagesCiProjectReportModule,
      ),
    ...canActivate(redirectUnauthorizedToLogIn),
  },
  {
    path: 'ci-projects/not-found/:projectId',
    loadChildren: () =>
      import('~ci-portal/pages/ci-project-not-found').then(
        m => m.PagesCiProjectNotFoundModule,
      ),
    ...canActivate(redirectUnauthorizedToLogIn),
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('~ci-portal/pages/admin/dashboard').then(
        m => m.PagesAdminDashboardModule,
      ),
    ...canActivate(redirectUnauthorizedToLogIn),
  },
  {
    path: 'resources-definitions',
    loadChildren: () =>
      import('~ci-portal/pages/resources-definitions').then(
        m => m.PagesResourcesDefinitionsModule,
      ),
    ...canActivate(redirectUnauthorizedToLogIn),
  },
  {
    path: 'admin/dashboard',
    loadChildren: () =>
      import('~ci-portal/pages/admin/dashboard').then(
        m => m.PagesAdminDashboardModule,
      ),
    ...canActivate(redirectUnauthorizedToLogIn),
  },
  {
    path: 'reports/smart-action-summary',
    loadChildren: () =>
      import('~ci-portal/pages/smart-action-summary').then(
        m => m.PagesSmartActionSummaryModule,
      ),
    ...canActivate(redirectUnauthorizedToLogIn),
  },
  {
    path: 'admin/manage-users',
    loadChildren: () =>
      import('~ci-portal/pages/admin/manage-users').then(
        m => m.PagesAdminManageUsersModule,
      ),
    ...canActivate(redirectUnauthorizedToLogIn),
  },
  {
    path: 'admin/manage-smart-actions',
    loadChildren: () =>
      import('~ci-portal/pages/admin/manage-smart-actions').then(
        m => m.PagesAdminManageSmartActionsModule,
      ),
    ...canActivate(redirectUnauthorizedToLogIn),
  },
  {
    path: 'admin/manage-ci-projects',
    loadChildren: () =>
      import('~ci-portal/pages/admin/manage-projects').then(
        m => m.PagesAdminManageProjectsModule,
      ),
    ...canActivate(redirectUnauthorizedToLogIn),
  },
  {
    path: 'admin/manage-hlc',
    loadChildren: () =>
      import('~ci-portal/pages/admin/manage-hlc').then(
        m => m.PagesAdminManageHlcModule,
      ),
    ...canActivate(redirectUnauthorizedToLogIn),
  },
  {
    path: 'admin/manage-org-units',
    loadChildren: () =>
      import('~ci-portal/pages/admin/manage-org-units').then(
        m => m.PagesAdminManageOrgUnitsModule,
      ),
    ...canActivate(redirectUnauthorizedToLogIn),
  },
  {
    path: 'admin/manage-goals',
    loadChildren: () =>
      import('~ci-portal/pages/admin/manage-goals').then(
        m => m.PagesAdminManageGoalsModule,
      ),
    ...canActivate(redirectUnauthorizedToLogIn),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('~ci-portal/pages/dashboard').then(m => m.PagesDashboardModule),
    ...canActivate(redirectUnauthorizedToLogIn),
  },
  {
    path: '',
    loadChildren: () =>
      import('~ci-portal/pages/dashboard').then(m => m.PagesDashboardModule),
    ...canActivate(redirectUnauthorizedToLogIn),
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  declarations: [AppComponent, LandingPageComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    EnvironmentModule.withEnvironment(environment),
    RouterModule.forRoot(routes),
    AngularFireModule.initializeApp(environment.firebase),
    StoreModule.forRoot(reducer, { metaReducers: [onLogoutMetaReducer] }),
    EffectsModule.forRoot([AuthEffects, NavigationEffects]),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({
      maxAge: 50,
      logOnly: environment.production,
      name: 'Heartland CC - CI Portal',
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    SharedModule,
    AuthModule,
    DomainsOrgUnitModule,
    DomainsCIProjectModule,
    DomainsPlanningModule,
    DomainsSmartActionsModule,
    DomainsUserModule,
    ComponentsLayoutModule,
    MatButtonModule,
  ],
  providers: [
    {
      provide: PERSISTENCE,
      useValue: 'local',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private store: Store) {
    store.dispatch(appPreInitializing());
  }
}
