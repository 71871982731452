import { createSelector } from '@ngrx/store';
import {
  EntityState,
  getEntities,
  getIds,
  renderEntitiesAsArray,
} from '~ci-portal/utils/entity-utils';
import { OrgUnit } from '../../models';
import { featureState, FeatureState } from '../feature.state';

export const getOrgUnitState = (state: FeatureState): EntityState<OrgUnit> =>
  state.orgUnits;

export const orgUnitState = createSelector(featureState, getOrgUnitState);

export const orgUnitEntities = createSelector(orgUnitState, getEntities);

export const orgUnitIds = createSelector(orgUnitState, getIds);

export const allOrgUnits = createSelector(
  orgUnitEntities,
  orgUnitIds,
  renderEntitiesAsArray,
);

export const sortedOrgUnits = createSelector(allOrgUnits, (units: OrgUnit[]) =>
  units.sort((a, b) =>
    a.name.localeCompare(b.name, undefined, { numeric: true }),
  ),
);
